import React, { useState, useContext } from "react";
import Box from "@mui/material/Box";
import { Grid, Typography } from "@mui/material";
import { IconButton } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";
import DashboardDatepicker from "../shared/DashboardDatepicker";
import {
  getCurrentDateYYYYMMDD,
  getDateBySubtractMonth,
} from "../../utils/DateUtils";
import dayjs from "dayjs";
import ACTDropdownList from "../shared/ACTDropdownList";
import { AppContext } from "../../context/AppContext";
import { getReasonForFailWithShowAll } from "../../utils/CommonUtils";
import DashboardDatepickerMobile from "../shared/DashboardDatepickerMobile";

const LocationToolbarMobile = (props) => {
  const {
    summaryType,
    summaryId,
    // exportHandler,
    // printHandler,
    refreshDataHandler,
  } = props;

  const appContext = useContext(AppContext);
  const onDateChange = (value, type) => {
    if (value && refreshDataHandler) {
      refreshDataHandler(value, 1);
    }
  };

  const refreshData = (data) => {
    if (refreshDataHandler) {
      refreshDataHandler(data, 2);
    }
  };

  return (
    <Grid
      sx={{ flexGrow: 1 }}
      container
      spacing={2}
      style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
    >
      <Grid item xs={6} sm={3}>
        <DashboardDatepickerMobile
          onRefreshData={onDateChange}
          type={2}
          defaultDate={dayjs()}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        {appContext.failedReasons && (
          <ACTDropdownList
            options={getReasonForFailWithShowAll(appContext.failedReasons)}
            title={"Select Reason for Fail"}
            type={5}
            onRefreshData={refreshData}
          />
        )}
      </Grid>

      <Grid item xs={12} sm={6}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: ".5rem",
          }}
        >
          {summaryType === "device" && (
            <div
              style={{
                display: "flex",
                fontSize: "1rem",
                fontWeight: "500",
                flexDirection: "row",
                marginRight: "1rem",
              }}
            >
              {"Device:"}
              <div
                style={{
                  fontSize: "1rem",
                  fontWeight: "400",
                  marginLeft: "0.25rem",
                }}
              >
                {summaryId}
              </div>
            </div>
          )}
          {summaryType === "account" && (
            <div
              style={{
                display: "flex",
                fontSize: "1rem",
                fontWeight: "500",
                flexDirection: "row",
                marginRight: "1rem",
              }}
            >
              {"Account:"}
              <div
                style={{
                  fontSize: "1rem",
                  fontWeight: "400",
                  marginLeft: "0.25rem",
                }}
              >
                {summaryId}
              </div>
            </div>
          )}

          {/* <IconButton onClick={printHandler} disabled>
          <PrintIcon />
        </IconButton> */}
          {/* <IconButton onClick={exportHandler} sx={{ bgcolor: "#ffffff" }}>
            <DownloadIcon />
          </IconButton> */}
        </Box>
      </Grid>
    </Grid>
  );
};

export default LocationToolbarMobile;
