import React, { useEffect, useContext, useMemo } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AppRoutes from "./routes/Routes";
import LoginRoutes from "./routes/LoginRoutes";
import MainLayout from "./components/sidebar/MainLayout";
import { AppContext } from "./context/AppContext";
import Footer from "./components/shared/Footer/Footer";
import { MainFooter } from "./components/shared/MainFooter";
import 'material-react-toastify/dist/ReactToastify.css';

function App() {
  const appContext = useContext(AppContext);

  const theme = useMemo(() =>
    createTheme({
      palette: {
        mode: appContext.darkMode ? 'dark' : 'light',
        primary: {
          light: "#4da6ff",
          main: "#1e86f0",
          dark: "#0b60b8",
          contrastText: "#fff",
        },
        secondary: {
          light: "#ffa569",
          main: "#f57b2a",
          dark: "#d65b09",
          contrastText: "#fff",
        },
      },
    },

    ), [appContext.darkMode]
  );

  // Check and load the dark mode state from localStorage when component mounts
  useEffect(() => {
    const savedDarkMode = localStorage.getItem("darkMode");
    if (savedDarkMode !== null) {
      appContext.setDarkMode(JSON.parse(savedDarkMode));
    }
  }, [appContext]);

  const toggleDarkMode = () => {
    appContext.setDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem("darkMode", JSON.stringify(newMode));
      return newMode;
    });
  };

  //Toggle dark mode on the body element
  useEffect(() => {
    if (theme.palette.mode === 'dark') {
      document.documentElement.classList.add('dark-mode');
    } else {
      document.documentElement.classList.remove('dark-mode');
    }
  }, [theme.palette.mode]);

  return (
    <ThemeProvider theme={theme}>
      <>
        <Box sx={{ display: "flex", width: "100%" }}>
          <CssBaseline />
          {!appContext.userDetails && !appContext.loading && (
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <LoginRoutes /> <Footer />
            </Box>
          )}
          {appContext.userDetails && !appContext.loading && (
            <>
              <MainLayout
                toggleDarkMode={toggleDarkMode}
              />
              <AppRoutes />
            </>
          )}
        </Box>
        {appContext.userDetails && !appContext.loading && (
          <MainFooter open={appContext.drawerOpen}>
            <Footer />
          </MainFooter>
        )}
      </>
    </ThemeProvider>
  );
}

export default App;
