import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { Main } from "../components/shared/Main";
import { DrawerHeader } from "../components/sidebar/DrawerHeader";
import BlockedDevicesContainer from "../components/blockedDevices/BlockedDevicesContainer";

const BlockedDevices = () => {
    const appContext = useContext(AppContext);

    return (
      <Main
        open={appContext.drawerOpen}
        sx={{
          overflowX: "hidden",
          height: '100%',
          minHeight: '100vh',
        }}
      >
        <DrawerHeader />
        <BlockedDevicesContainer />
      </Main>
    );
}

export default BlockedDevices;