import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";
import React from "react";
import { formatDateDDMMMMMYYYY } from "../../utils/DateUtils";

const SummaryDeviceInfo = (props) => {
  const { title, data, summaryType, summaryId } = props;

  return (
    <Card>
      <CardHeader
            sx={{
              padding: "0.313rem 1rem 0 1rem",
            }}
        title={
          <Typography
            variant="body1"
            component="div"
            sx={{
              fontSize: "1.1rem",
              fontWeight: "500",
            }}
          >
            {title}
          </Typography>
        }
      />
      <CardContent
        sx={{
          "&.MuiCardContent-root:last-child": {
            padding: "0 0 0.25rem 1rem",
        }
      }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            height: "170px",
            marginRight: "0.75rem",
          }}
        >
          {summaryType === "device" && (
            <>
              {
                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    fontSize: "0.85rem",
                    fontWeight: "400",
                  }}
                >
                  {data ? data.OsVersion : ""}
                </Typography>
              }
              {
                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    fontSize: "0.85rem",
                    fontWeight: "400",
                  }}
                >
                  First Seen:{" "}
                  {data ? formatDateDDMMMMMYYYY(data.FirstSeen) : ""}
                </Typography>
              }
              {
                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    fontSize: "0.85rem",
                    fontWeight: "400",
                  }}
                >
                  Device ID: {data ? data.DeviceId : ""}
                </Typography>
              }
              {
                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    fontSize: "0.85rem",
                    fontWeight: "400",
                  }}
                >
                  SDK Version: {data ? data.SDKVersion : ""}
                </Typography>
              }
              {
                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    fontSize: "0.85rem",
                    fontWeight: "400",
                  }}
                >
                  Status: {data ? data.Status : ""}
                </Typography>
              }
            </>
          )}
          {summaryType === "account" && (
            <>
              {
                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    fontSize: "0.85rem",
                    fontWeight: "400",
                  }}
                >
                  First Seen:{" "}
                  {data ? formatDateDDMMMMMYYYY(data.FirstSeen) : ""}
                </Typography>
              }
              {
                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    fontSize: "0.85rem",
                    fontWeight: "400",
                  }}
                >
                  Last Seen: {data ? formatDateDDMMMMMYYYY(data.LastSeen) : ""}
                </Typography>
              }
              {
                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    fontSize: "0.85rem",
                    fontWeight: "400",
                  }}
                >
                  Account ID: {summaryId}
                </Typography>
              }
              {
                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    fontSize: "0.85rem",
                    fontWeight: "400",
                  }}
                >
                  Status: {data ? data.Status : ""}
                </Typography>
              }
            </>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default SummaryDeviceInfo;
