import React, { useState } from "react";
import { styled } from "@mui/system";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// Define a custom MenuItem component
const CustomMenuItem = styled(MenuItem)(({ theme, isSelected }) => ({
  position: "relative",
  backgroundColor: "transparent", // Default background
  // backgroundColor: isSelected ? theme.palette.grey[300] : 'transparent',
  "&:hover": {
    backgroundColor: theme.palette.mode === 'light' && theme.palette.grey[200],
  },
  "&:focus": {
    backgroundColor: theme.palette.mode === 'light' && theme.palette.grey[300],
    "&:hover": {
      backgroundColor: theme.palette.mode === 'light' && theme.palette.grey[200],
    },
  },
  "&:focusVisible, &:focusWithin, &:active": {
    backgroundColor: theme.palette.mode === 'light' && theme.palette.grey[200], // Custom color when clicked
  },
  // Ensures no default styling is applied during click
  "&.Mui-focusVisible, &.Mui-selected, &.Mui-focusVisible.Mui-selected": {
    backgroundColor: theme.palette.mode === 'light' && theme.palette.grey[300],
    outline: "none",
    boxShadow: "none",
  },

  // Disable transition effects that might cause delay
  transition: "background-color 0s",
  // Add a divider-like border after the last child using a pseudo-element
  "&::after": {
    content: '""',
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    height: "1px",
    backgroundColor: theme.palette.divider,
  },
}));

const CustomSelect = styled(Select)(({ theme }) => ({
  minWidth: 170, // Prevent width change
  maxWidth: 170,
  minHeight: 40,
  maxHeight: 40,
  border: "none",
  background: "none",
  boxShadow: "none",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&.MuiInputBase-root": {
    background: "none",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiSelect-select": {
    background: "none",
  },
}));

const ACTDropdownList = (props) => {
  const { options, title, onRefreshData, type, selectedItem, name } = props;
  const [selectedItemId, setSelectedItemId] = useState(selectedItem ? selectedItem.id : options[0].id);
  const theme = useTheme();
  const handleChange = (event) => {
    if (onRefreshData) {
      const selectedItem = options.find(
        (item) => item.id === event.target.value
      );
      onRefreshData(selectedItem, type, name);
    }
    setSelectedItemId(event.target.value);
  };

  return (
    <CustomSelect
      placeholder={title}
      onChange={handleChange}
      value={selectedItemId}
      // value={selectedItemId ? selectedItemId : ""}
    >
      {options.map((item) => (
        <CustomMenuItem key={item.id} value={item.id}>
          <Typography
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              paddingRight: "30px",
              color: theme.palette.text.primary,
            }}
          >
            {item.label}
          </Typography>
        </CustomMenuItem>
      ))}
    </CustomSelect>
  );
};

export default ACTDropdownList;
