import dayjs from "dayjs";

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone)

export const formatDateYYYYMMDDHHMMSS = (date) => {
  return dayjs(date).format("YYYY-MM-DDTHH:mm:ss");
};

export const formatDateYYYYMMDD = (date) => {
  return dayjs(date).format("YYYY-MM-DD");
};

export const getFormattedCurrentDate = () => {
  return dayjs().format("YYYY-MM-DDTHH:mm:ss");
};

export const formatDateBasedOnLocale = (date) => {
  var userLocale = Intl.DateTimeFormat().resolvedOptions().locale;
  if (!userLocale) {
    userLocale = navigator.language || navigator.userLanguage;
  }
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const localDateTime = dayjs.utc(date).tz(userTimezone);

  if (userLocale.toLowerCase() === "en-gb") {
    return localDateTime.format("DD/MM/YYYY HH:mm:ss");
  } else {
    return localDateTime.format("MM/DD/YYYY HH:mm:ss");
  }
};

export const gteFormatCurrentDate = () => {
  return dayjs().format("MM/DD/YYYY");
};

export const getCurrentDateYYYYMMDD = () => {
  return dayjs().format("YYYY-MM-DD");
};

export const getCurrentYearYYYY = () => {
  return dayjs().format("YYYY");
};

export const getCurrentDateYYYYMMDDWithTime = () => {
  const strDate = dayjs().format("YYYYMMDD");
  const strTime = dayjs().format("HHmm");

  return strDate + "_" + strTime;
};

export const getLast24HoursDateTimeFromCurrentDateTime = () => {
  // Date and time 24 hours ago
  const dateTime24HoursAgo = dayjs().subtract(24, "hour");

  return formatDateYYYYMMDDHHMMSS(dateTime24HoursAgo);
};

export const getLast24HoursDateTimeFromSelectedDateTime = (date) => {
  // Date and time 24 hours ago
  const dateTime24HoursAgoFromSelectedDate = date.subtract(24, "hour");

  return formatDateYYYYMMDDHHMMSS(dateTime24HoursAgoFromSelectedDate);
};

export const subtractHours = (hours) => {
  // Substract hours from current date time
  const dateTimeSubstractedHours = dayjs().subtract(hours, "hour");

  return formatDateYYYYMMDDHHMMSS(dateTimeSubstractedHours);
};

export const getDayBySubtractingNumDays = (numDay) => {
  // Substract day by number of days
  const dateBySubtractingNumDays = dayjs().subtract(numDay, "day");

  return formatDateYYYYMMDD(dateBySubtractingNumDays);
};

export const subtractMinutes = (minutes) => {
  // Substract hours from current date time
  const dateTimeSubstractedMinutes = dayjs().subtract(minutes, "minute");

  return formatDateYYYYMMDDHHMMSS(dateTimeSubstractedMinutes);
};

export const getCurrentTimeInHHMM = () => {
  return dayjs().format("HH:mm");
};

export const getPreviousDateBasedOnTransactionPeriod = (daysParam) => {
  let days = daysParam;
  let date = new Date();
  let lastDate = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
  let res = formatDateYYYYMMDD(lastDate);
  return res;
};

export const getDateBySubtractMonth = (subtract) => {
  const monthsAgo = dayjs().subtract(subtract, "month");
  return monthsAgo.format("YYYY-MM-DD");
};

export const formatDateDDMMMMMYYYY = (date) => {
  return dayjs(date).format("DD MMMM YYYY");
};

export const getDateFromString = (strDate) => {
  if (strDate && strDate.length > 0) {
    return dayjs(strDate);
  } else {
    return dayjs();
  }
};
