import { removeAuthInfo } from "./AuthUtils";

// public enum ResponseCode
// {
//     opSuccess = 0,
//     //success, including location validated

//     opNotValidated = 1,
//     //location not validated

//     opNotAbleToValidate = 2,

//     opInvalidParams = 9,
//     //invalid parameters

//     opFailure = 10,
//     //Operation failed

//     opInvalidCredentials = 11,
//     //Invalid Credentials

//     opExceptionError = 12,
//     //caught in a try catch

//     opJWTcreationError = 13,
//     //error when creating the JWT

//     opJWTExpired = 14,
//     //JWT expired

//     opJWTinvalid = 15,
//     //JWT invalid

//     opNoSQLDBItemAlreadyExists = 16,
//     //item already exists

//     opNoSQLDBItemNotFound = 17,
//     //item not found in cosmos db

//     opNoSQLDBError = 18,
//     //error in cosmos db

//     opNoSQLDBHttpConflict = 19,
//     //conflict in cosmos db

//     opInvalidLatitude = 20,
//     //invalid latitude

//     opInvalidLongitude = 21,
//     //invalid longitude

//     opInvalidDBConfiguration = 22,
//     //invalid db configuration

//     opInvalidUsername = 23,
//     //invalid username

//     opInvalidPassword = 24,
//     //invalid password

//     opInactiveUser = 25,
//     //inactive user

//     opNoReports = 26,
//     //no reports for current user

//     opNoApps = 27,
//     //no apps for current user

//     opReportNotAllowed = 28,
//     //report not allowed for current user

//     opAppNotAllowed = 29,
//     //app not allowed for current user

//     opMissingValuesFromJwt = 30,
//     //missing values from jwt

//     opInvalidStartDate = 31,
//     //invalid start date

//     opInvalidEndDate = 32,
//     //invalid end date

//     opIncorrectReportId = 33,
//     //incorrect report id

// }

export const isTokenError = (resCode) => {
  if (resCode === 13 || resCode === 14 || resCode === 15) {
    return true;
  }

  return false;
};

export const getExecptionMessageByCode = (resCode) => {
  var message = "";
  switch (resCode) {
    case 1:
      message = "Location not validated";
      break;
    case 2:
      message = "Not able to validate";
      break;
    case 9:
      message = "Invalid parameters";
      break;
    case 10:
      message = "Operation failed";
      break;
    case 11:
      message = "Invalid Credentials";
      break;
    case 12:
      message = "Unknown exception";
      break;
    case 13:
      message = "Error when creating the Token";
      break;
    case 14:
      message = "Token expired";
      break;
    case 15:
      message = "Invalid Token";
      break;
    case 16:
      message = "Item already exists";
      break;
    case 17:
      message = "Item not found";
      break;
    case 18:
      message = "Error in cosmos database";
      break;
    case 19:
      message = "Conflict in cosmos database";
      break;
    case 20:
      message = "Invalid latitude";
      break;
    case 21:
      message = "Invalid longitude";
      break;
    case 22:
      message = "Invalid database configuration";
      break;
    case 23:
      message = "Invalid username";
      break;
    case 24:
      message = "Invalid password";
      break;
    case 25:
      message = "Inactive user";
      break;
    case 26:
      message = "there is no reports for current user";
      break;
    case 27:
      message = "there is no apps for current user";
      break;
    case 28:
      message = "Report not allowed for current user";
      break;
    case 29:
      message = "App not allowed for current user";
      break;
    case 30:
      message = "Missing values from jwt";
      break;
    case 31:
      message = "Invalid start date";
      break;
    case 32:
      message = "Invalid end date";
      break;
    case 33:
      message = "Incorrect report id";
      break;
    default:
      message = "Something went wrong. Please trying again.";
  }

  return message;
};

export const getExecptionMessageFromError = (err) => {
  try {
    const objMessage = err.message;
    const errMessage = objMessage.message;
    if (!errMessage) {
      return "Something went wrong. Please trying again.";
    } else {
      return errMessage;
    }
  } catch (err) {
    return "Something went wrong. Please trying again.";
  }
};

export const showServerExceptionOnToast = (toast, type, status) => {
  const message = getExecptionMessageByCode(status);

  if (type === 1) {
    toast.warning(message);
  } else if (type === 2) {
    toast.error(message);
  } else {
    toast.info(message);
  }
};

export const showCatchedExceptionOnToast = (toast, type, err) => {
  const message = getExecptionMessageFromError(err);
  if (type === 1) {
    toast.warning(message);
  } else if (type === 2) {
    toast.error(message);
  } else {
    toast.info(message);
  }
};

export const validateAnsShowServerException = (
  toast,
  type,
  status,
  appContext
) => {
  const tokenError = isTokenError(status);
  if (tokenError) {
    removeAuthInfo();
    appContext.setUserDetails(null);
  } else {
    showServerExceptionOnToast(toast, type, status);
  }
};
