// import axios from "axios";
import { axiosApiInstance } from "../network/axiosmanager";
import HttpException from "../common/exceptions/HttpException";
import { BASE_URL, LOGIN } from "../constants/APIConstants";

// export const loginUser = async (token) => {
//   const response = await axiosApiInstance
//     .get(BASE_URL + LOGIN, {
//       headers: {
//         // "Cache-Control": "no-store",
//       },
//     })
//     .catch(HttpException.create);
//   return response.data;
// };

// export const executeRequest = async (payload) => {
//   const response = await axiosApiInstance
//     .post(BASE_URL + LOGIN, payload, {
//       headers: {
//         "Content-Type": "application/x-www-form-urlencoded",
//       },
//     })
//     .catch(HttpException.create);
//   return response.data;
// };

export const loginUser = async (payload) => {
  const response = await axiosApiInstance
    .post(BASE_URL + LOGIN, payload)
    .catch(HttpException.create);
  return response.data;
};