import React, { useContext, useState } from "react";
import "./TopInfoBarStyles.scss";
import NestedModal from "../search/SearchModal";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { styled, useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import SegmentIcon from "@mui/icons-material/Segment";
import { DRAWER_WIDTH } from "../../constants/Constants";
import { AppContext } from "../../context/AppContext";
import { getCurrentTimeInHHMM } from "../../utils/DateUtils";
import { removeAuthInfo } from "../../utils/AuthUtils";
import { useMediaQuery } from "@mui/material";
import AnimatedSearchTab from "../search/animatedSearchBar/AnimatedSearchTab";
import LogOutButton from "../shared/LogOutButton";

import { Brightness4, Brightness7 } from '@mui/icons-material';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: `${DRAWER_WIDTH}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: "flex-start",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(2),
  // Override media queries injected by theme.mixins.toolbar
  "@media all": {
    minHeight: 64,
  },
}));

const TopInfoBar = (props) => {
  const theme = useTheme();
  const appContext = useContext(AppContext);
  const { handleDrawerOpen, toggleDarkMode } = props;
  const [showSearchBox, setShowSearchBox] = useState(false);
  const isDesktop = useMediaQuery(theme.breakpoints.up("md")); // true if screen is md (medium) or larger
  const [searchItem, setSearchItem] = useState(null);

  const onClickLogout = () => {
    removeAuthInfo();
    appContext.clearAllStates();
  };

  const onClickSearch = () => {
    setShowSearchBox(!showSearchBox);
  };

  const onCloseDialog = () => {
    // setShowSearchBox(!showSearchBox);
    setIsAdvancedOpen(!isAdvancedOpen);
  };

  /** function to toggle advanced search panel */
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  function toggleAdvanced() {
    setIsAdvancedOpen(!isAdvancedOpen);
  }

  return (
    <AppBar
      position="fixed"
      open={appContext.drawerOpen}
      // sx={{ bgcolor: "default" }}
      color="default"
    >
      <Toolbar style={{ padding: 0 }}>
        <div
          style={{
            display: "flex",
            height: isDesktop ? 64 : 56,
            backgroundColor: "#1e86f0",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              ml: 2,
              mr: 2,
              ...(appContext.drawerOpen && { display: "none" }),
            }}
          >
            <SegmentIcon sx={{ color: "#ffffff" }}/>
          </IconButton>
        </div>
        <div style={{ flexGrow: 1 }}>
          <div style={{ display: "flex", gap: "5px", marginLeft: "10px" }}>
            <div className="user-name" style={{ color: theme.palette.text.primary }}>
              {appContext.userDetails
                ? appContext.userDetails.Data.FirstName +
                  " " +
                  appContext.userDetails.Data.LastName
                : ""}
            </div>

            <div className="user-time" style={{ color: theme.palette.text.primary }}>{getCurrentTimeInHHMM()}</div>
          </div>
          <div className="user-location" style={{ marginLeft: "10px", color: theme.palette.text.primary }}>
            {appContext.userDetails ? appContext.userDetails.Data.Address : ""}
          </div>
        </div>
        <AnimatedSearchTab
          toggleAdvanced={toggleAdvanced}
          searchItem={setSearchItem}
        />
          <IconButton color="inherit" onClick={toggleDarkMode}>
            {appContext.darkMode ? <Brightness7 /> : <Brightness4 />}
          </IconButton>
        <LogOutButton 
          className="log-out-button"
          // sx={{ ml: 1, mr: 2 }}
          onClick={onClickLogout}
        >
          LOGOUT
        </LogOutButton>
      </Toolbar>
      <NestedModal
        openModal={isAdvancedOpen}
        closeModal={onCloseDialog}
        searchItem={searchItem}
      />
    </AppBar>
  );
};

export default TopInfoBar;
