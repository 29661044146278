import {
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";

import Login from "../pages/Login";
import ForgottenPassword from "../pages/ForgottenPassword";
import ResetPassword from "../pages/ResetPassword";

const LoginRoutes = () => {
  const location = useLocation();
  return (
    <Routes>
      <Route
        path="*"
        element={<Navigate to="/login" state={{ from: location }} replace />}
      />
      <Route path="/login" element={<Login />} />
      <Route path="/forgotten-password" element={<ForgottenPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
    </Routes>
  );
};

export default LoginRoutes;
