export const LOOKUP_MENUS = [
  {
    title: "All Checks",
    code: -2,
    items: [],
    selected: false
  },
  {
    title: "Approved Checks",
    code: 0,
    items: [],
    selected: true
  },
  {
    title: "Failed Checks",
    code: -1,
    selected: false,
    items: [
      {
        title: "Select All Failed Checks",
        code: -1,
        items: [],
        selected: false,
      },
      {
        title: "Outside Location",
        code: 3,
        items: [],
        selected: true,
      },
      {
        title: "Spoofing Technology",
        code: 101,
        selected: false,
        items: [
          {
            title: "Mock Location Detected",
            code: 7,
            selected: false,
          },
          {
            title: "Developer Mode",
            code: 9,
            selected: false,
          },
        ],
      },
      {
        title: "Emulator Detected",
        code: 15,
        selected: false,
        items: [],
      },
      {
        title: "Modified Device",
        code: 103,
        selected: false,
        items: [
          {
            title: "Location Service Disabled",
            code: 5,
            selected: false,
          },
          {
            title: "Modified Device",
            code: 6,
            selected: false,
          },
        ],
      },
      {
        title: "VPN Detected",
        code: 14,
        items: [],
        selected: false,
      },
      {
        title: "Proxy Detected",
        code: 12,
        items: [],
        selected: false,
      },
      {
        title: "Block Status",
        code: 104,
        selected: false,
        items: [
          {
            title: "Device Blocked",
            code: 10,
            selected: false,
          },
          {
            title: "Account Blocked",
            code: 11,
            selected: false,
          },
          {
            title: "Device Blocked Globally",
            code: 13,
            selected: false,
          },
        ],
      },
      {
        title: "Others",
        code: 105,
        selected: false,
        items: [
          {
            title: "Unknown Location",
            code: 1,
            selected: false,
          },
          {
            title: "Device Not Supported",
            code: 2,
            selected: false,
          },
          {
            title: "Undetermined Location",
            code: 8,
            selected: false,
          },
          {
            title: "Other",
            code: 4,
            selected: false,
          },
        ],
      },
    ],
  },
];
