import { useContext } from "react";
import { styled } from "@mui/material/styles";
import { DRAWER_WIDTH } from "../../constants/Constants";
import { AppContext } from "../../context/AppContext";

export const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => {
  const appContext = useContext(AppContext);
  return {
    flexGrow: 1,
    backgroundColor: appContext.darkMode ? 'dark' : "#ececec",
    padding: theme.spacing(2),
    height: '100%',
    paddingBottom: '3rem',
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${DRAWER_WIDTH}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  };
});
