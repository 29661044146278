export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// API endpoints
export const LOGIN = "/api/v1.1/Client/Login";
export const REPORT_D1T = "/api/v1.1/Reports/ReportD1T";
export const REPORT_D2T = "/api/v1.1/Reports/ReportD2T";
export const REPORT_B1T = "/api/v1.1/Reports/ReportB1T";
export const REPORT_B2T = "/api/v1.1/Reports/ReportB2T";
export const REPORT_B3T = "/api/v1.1/Reports/ReportB3T";
export const REPORT_S1T = "/api/v1.1/Reports/ReportS1T";
export const REPORT_S2T = "/api/v1.1/Reports/ReportS2T";
export const LIST_FAILED_REASON = "/api/v1.1/Reports/ListReasonsForFail";
export const REPORT_DEV1T = "/api/v1.1/Reports/ReportDev1T";
export const REPORT_DEV2T = "/api/v1.1/Reports/ReportDev2T";
export const REPORT_ACC1T = "/api/v1.1/Reports/ReportAcc1T";
export const REPORT_ACC2T = "/api/v1.1/Reports/ReportAcc2T";
export const GET_ALL_BLOCKED_ACCOUNTS =
  "/api/v1.1/Client/GetAllBlockedAccounts";
export const BLOCK_ACCOUNT = "/api/v1.1/Client/BlockAccount";
export const UNBLOCK_ACCOUNT = "/api/v1.1/Client/UnblockAccount";
export const GET_ALL_BLOCKED_DEVICES = "/api/v1.1/Client/GetAllBlockedDevices";
export const BLOCK_DEVICE = "/api/v1.1/Client/BlockDevice";
export const UNBLOCK_DEVICE = "/api/v1.1/Client/UnblockDevice";
export const GET_ALL_GLOBAL_BLOCKED_DEVICES =
  "/api/v1.1/Client/GetGlobalBlockedDevices";
