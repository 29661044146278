import React, { useContext } from "react";
import { Main } from "../components/shared/Main";
import { AppContext } from "../context/AppContext";
import { DrawerHeader } from "../components/sidebar/DrawerHeader";
import SavedReportContainer from "../components/savedReports/SavedReportContainer";

const SavedReport = () => {
  const appContext = useContext(AppContext);

  return (
    <Main
      open={appContext.drawerOpen}
      sx={{
        overflowX: "hidden",
        height: "100%",
        minHeight: "100vh",
      }}
    >
      <DrawerHeader />
      <SavedReportContainer />
    </Main>
  );
};

export default SavedReport;
