import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import "./TextFieldOutlinedInputStyles.scss";

export default function TextFieldOutlinedInput(props) {
    const { placeHolderText, onTextChange } = props;
    const [selectData, setData] = React.useState([]);

    const handleChange = (event) => {
        const {
          target: { value },
        } = event;
        setData(
          // On fill we get a stringified value.
          typeof value === "string" ? value.split(",") : value
        );
        onTextChange(value);
      };

  return (
    <Box
      component="form"
      value={selectData}
      onChange={handleChange}
      renderValue={(selected) => {
        if (selected.length === 0) {
          return <p>{placeHolderText}</p>;
        }
        return selected.join();
      }}
      noValidate
      autoComplete="off"
    >
      <TextField label={placeHolderText}
      sx={{ 
        mt: 1, 
        mb: 1,
        borderRadius: 10,
        boxShadow: 0,
        "& .MuiInputBase-root": {
          bgcolor: "#ffffff",
          width: "85vw",
          maxWidth: "25rem",
          height: "3.5rem",
          padding: "1rem",
            "& .MuiInputBase-input": {
              height: "3rem",
            }
        }
      }} 
      variant="outlined" />
    </Box>
  );
}