import React from "react";
import { Box } from "@mui/material";
import packageJson from "../../../../package.json";
import geosafeLogo from "../../../../src/assets/images/geosafe-logo.png";
import geosafeLogoWhite from "../../../assets/images/geosafe-logo-white.png";
import { getCurrentYearYYYY } from "../../../../src/utils/DateUtils";
import "./FooterStyles.scss";

import { useTheme } from '@mui/material/styles';

const currentYear = getCurrentYearYYYY();
const version = packageJson.version;

const Footer = () => {
  const theme = useTheme();
  return (
    <>
        <Box
          className="footer-box"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent={"center"}
          style={{ 
            backgroundColor: theme.palette.mode === 'dark' ? '#1c1c1c' : '#d8d8d8',
            color: theme.palette.text.primary}}
        >
          <div className="footer-panel">
            <img
              src={theme.palette.mode === 'dark' ? geosafeLogoWhite : geosafeLogo}
              alt="Geosafe logo"
              className="geosafe-footer-logo"
            />
            <p className="footer-panel-version">V {version}</p>
          </div>
          <div className="footer-panel-copyright">
            &copy; Copyright {currentYear} Advanced Compliance Technology Ltd
          </div>
        </Box>
    </>
  );
};

export default Footer;
