import { axiosApiInstance } from "../network/axiosmanager";
import HttpException from "../common/exceptions/HttpException";
import {
  BASE_URL,
  REPORT_DEV1T,
  REPORT_DEV2T,
  REPORT_ACC1T,
  REPORT_ACC2T,
} from "../constants/APIConstants";

export const fetchDeviceSummaryReport = async (payload) => {
  const response = await axiosApiInstance
    .post(BASE_URL + REPORT_DEV1T, payload)
    .catch(HttpException.create);
  return response.data;
};

export const fetchDeviceTransactionsSummaryReport = async (payload) => {
  const response = await axiosApiInstance
    .post(BASE_URL + REPORT_DEV2T, payload)
    .catch(HttpException.create);
  return response.data;
};

export const fetchAccountSummaryReport = async (payload) => {
  const response = await axiosApiInstance
    .post(BASE_URL + REPORT_ACC1T, payload)
    .catch(HttpException.create);
  return response.data;
};

export const fetchAccountTransactionsSummaryReport = async (payload) => {
  const response = await axiosApiInstance
    .post(BASE_URL + REPORT_ACC2T, payload)
    .catch(HttpException.create);
  return response.data;
};
