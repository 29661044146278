import React, { useContext } from "react";
import { APIProvider, Map } from "@vis.gl/react-google-maps";
import { getMapConfig } from "../../config/MapConfig";
import { DEFAULT_ZOOM_LEVEL } from "../../constants/Constants";
import { AppContext } from "../../context/AppContext";
import { getReasonForFailWithShowAll } from "../../utils/CommonUtils";
import { LocationTabClusteredMarkers } from "../home/maps/clustermap/LocationTabClusteredMarkers";

const SummaryMap = (props) => {
  const { data, summaryType, summaryId } = props;
  const appContext = useContext(AppContext);
  // const { MaxLongitude, MaxLatitude } = appContext.selectedUserApp.ViewPort;
  // const position = { lat: MaxLatitude, lng: MaxLongitude };
  const { MinLongitude, MinLatitude, MaxLongitude, MaxLatitude } = appContext.selectedUserApp.ViewPort;
  // const position = { lat: MinLatitude, lng: MinLongitude };
  // const position = { lat: 50.822529, lng: -0.137163 };
  // var bounds = new window.google.maps.LatLngBounds();
  // bounds.extend({ lat: MinLatitude, lng: MinLongitude });
  // bounds.extend({ lat: MaxLatitude, lng: MaxLongitude });
  // var center = bounds.getCenter();

  const center = {
    lat: (MinLatitude + MaxLatitude) / 2,
    lng: (MinLongitude + MaxLongitude) / 2,
  };

  const { APIKey, MapId } = getMapConfig();

  return (
    <APIProvider apiKey={APIKey}>
      <div style={{ height: "75vh", width: "100%" }}>
        <Map
          defaultZoom={DEFAULT_ZOOM_LEVEL}
          defaultCenter={center}
          mapId={MapId}
          gestureHandling={"auto"}
          disableDefaultUI={true}
          zoomControl={true}
          streetViewControl={true}
          fullscreenControl={true}
        >
          {data && (
            <LocationTabClusteredMarkers
              points={data}
              summaryType={summaryType}
              summaryId={summaryId}
              reasonsForFail={getReasonForFailWithShowAll(
                appContext.failedReasons
              )}
            />
          )}
        </Map>
      </div>
    </APIProvider>
  );
};
export default SummaryMap;
