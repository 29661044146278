import { axiosApiInstance } from "../network/axiosmanager";
import HttpException from "../common/exceptions/HttpException";

import {
  GET_ALL_BLOCKED_ACCOUNTS,
  BLOCK_ACCOUNT,
  UNBLOCK_ACCOUNT,
  BASE_URL,
  GET_ALL_BLOCKED_DEVICES,
  BLOCK_DEVICE,
  UNBLOCK_DEVICE,
  GET_ALL_GLOBAL_BLOCKED_DEVICES,
} from "../constants/APIConstants";

export const getAllBlockedAccounts = async (payload) => {
  const response = await axiosApiInstance
    .post(BASE_URL + GET_ALL_BLOCKED_ACCOUNTS, payload)
    .catch(HttpException.create);
  return response.data;
};

export const blockAccount = async (payload) => {
  const response = await axiosApiInstance
    .post(BASE_URL + BLOCK_ACCOUNT, payload)
    .catch(HttpException.create);
  return response.data;
};

export const unblockAccount = async (payload) => {
  const response = await axiosApiInstance
    .post(BASE_URL + UNBLOCK_ACCOUNT, payload)
    .catch(HttpException.create);
  return response.data;
};

export const getAllBlockedDevices = async (payload) => {
  const response = await axiosApiInstance
    .post(BASE_URL + GET_ALL_BLOCKED_DEVICES, payload)
    .catch(HttpException.create);
  return response.data;
};

export const getAllGlobalBlockedDevices = async (payload) => {
  const response = await axiosApiInstance
    .post(BASE_URL + GET_ALL_GLOBAL_BLOCKED_DEVICES, payload)
    .catch(HttpException.create);
  return response.data;
};

export const blockDevice = async (payload) => {
  const response = await axiosApiInstance
    .post(BASE_URL + BLOCK_DEVICE, payload)
    .catch(HttpException.create);
  return response.data;
};

export const unblockDevice = async (payload) => {
  const response = await axiosApiInstance
    .post(BASE_URL + UNBLOCK_DEVICE, payload)
    .catch(HttpException.create);
  return response.data;
};
