import React from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./DashboardDatepickerStyles.scss";
import {
  formatDateYYYYMMDD,
} from "../../utils/DateUtils";
// import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/en';
import { useTheme } from "@emotion/react";

// dayjs.extend(localizedFormat);

var userLocale = Intl.DateTimeFormat().resolvedOptions().locale;
if(!userLocale){
userLocale = navigator.language || navigator.userLanguage;
}

// Dynamically import the locale
// import(`dayjs/locale/${userLocale}`).then(() => {
//   dayjs.locale(userLocale);
// });

const DashboardDatepicker = (props) => {
  const { onRefreshData, type, defaultDate } = props;
  const theme = useTheme();
  const handleDateChange = (newDate) => {
    if (newDate) {
      const formattedDate = formatDateYYYYMMDD(newDate);
      if (onRefreshData) {
        onRefreshData(formattedDate, type);
      }
    }
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={userLocale.toLowerCase()}>
      <DatePicker
        format={userLocale.toLowerCase() === "en-gb" ? "DD/MM/YYYY" : "MM/DD/YYYY"}
        defaultValue={defaultDate ? defaultDate : dayjs()}
        sx={{
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          minWidth: "150px",
          maxWidth: "150px",
        }}
        showDaysOutsideCurrentMonth
        InputProps={{
          sx: { "& .MuiPickersYear-yearButton": { textAlign: "center" } },
        }}
        slots={{ openPickerIcon: ArrowDropDownIcon }}
        slotProps={{
          textField: {
            InputProps: {
              sx: {
                textAlign: "left",
                ".MuiInputBase-input": {
                  textAlign: "left",
                  color: theme.palette.text.primary + '!important'
                },
              },
            },
          },
        }}
        onChange={handleDateChange}
      />
    </LocalizationProvider>
  );
};

export default DashboardDatepicker;
