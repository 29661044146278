import React, { useContext } from "react";
import { Box } from "@mui/material";
import ACTDropdownWithCollapseMenuItemList from "./ACTDropdownWithCollapseMenuItemList";
import DashboardTabHeader from "../home/DashboardTabHeader";
import { AppContext } from "../../context/AppContext";
import { useTheme } from "@emotion/react";
import "./ACTDashboardToolbarStyles.scss";

const ACTDashboardToolbar = ( props ) => {
  
  const { dashboardStates, setDashboardData } = useContext(AppContext);
  const theme = useTheme();
  const refreshData = (newValue, type, key) => {
    if (onRefreshData) {
      onRefreshData(newValue, type);
      if (type === 3) {
        setDashboardData("date", {
          startDate: newValue,
          endDate: newValue,
        });
      } else {
        setDashboardData(key, newValue);
      }
    }
  };
  const appContext = useContext(AppContext);
  const { reasonsForFail, onRefreshData, totalRecords } = props;

  const dashboardHeaderButtonSelect = (value) => {
    if (value === 0) {
      appContext.setSelectedTab(0);
    } else {
      appContext.setSelectedTab(1);
    }
  };

  return (
    <Box
      className="toolbar-panel"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "0.5rem",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "0.2rem 0.8rem 0.2rem 0.4rem",
          color: "theme.palette.text.primary",
          // backgroundColor:
          //   theme.palette.mode === "dark" ? "#1c1c1c" : "#ffffff",
          borderRadius: "0.3rem",
          minWidth: "18.75rem",
        }}
      >
        {reasonsForFail && (
          <ACTDropdownWithCollapseMenuItemList
            name={"failed_reasons"}
            options={reasonsForFail}
            title={"Select Reason for Fail"}
            type={5}
            onRefreshData={refreshData}
            selectedItem={dashboardStates["failed_reasons"] || null}
          />
        )}
        ({totalRecords})
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <DashboardTabHeader
          onTabSelect={dashboardHeaderButtonSelect}
          selectedTabItem={appContext.selectedTab}
        />
      </div>
    </Box>
  );
};

export default ACTDashboardToolbar;
