import { Box } from "@mui/material";
import { DrawerHeader } from "../components/sidebar/DrawerHeader";

const ContactSupport = () => {
  return (
    <Box style={{ padding: "1rem" }}>
      <DrawerHeader />
      <div style={{ color: "black" }}>Contact Support</div>
    </Box>
  );
};

export default ContactSupport;

