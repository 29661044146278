import { USER_INFO, JWT_TOKEN } from "../constants/Constants";
import Cookies from "js-cookie";

export const saveUserInfo = (data) => {
    localStorage.setItem(USER_INFO, JSON.stringify(data));
}

export const saveUserToken = (token) => {
    Cookies.set(JWT_TOKEN, token)
    // Cookies.set(JWT_TOKEN, response.JWTid, {
    //   httpOnly: true,
    //   secure: false, // Set to true if using HTTPS
    //   sameSite: "Strict", // Prevent CSRF attacks
    //   expires: 1
    // });
}

export const removeAuthInfo = () => {
    Cookies.remove(JWT_TOKEN, { path: '/', domain: '.localhost' })
    localStorage.removeItem(USER_INFO);
}



