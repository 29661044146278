import { axiosApiInstance } from "../network/axiosmanager";
import HttpException from "../common/exceptions/HttpException";

import {
  BASE_URL,
  REPORT_D1T,
  REPORT_D2T,
  REPORT_B1T,
  REPORT_B2T,
  REPORT_B3T,
  LIST_FAILED_REASON
} from "../constants/APIConstants";

export const fetchDashboardReport = async (payload) => {
  const response = await axiosApiInstance
    .post(BASE_URL + REPORT_D1T, payload)
    .catch(HttpException.create);
  return response.data;
};

export const fetchSelectedLocationDetails = async (payload) => {
  const response = await axiosApiInstance
    .post(BASE_URL + REPORT_D2T, payload)
    .catch(HttpException.create);
  return response.data;
};

export const fetchActivities = async (payload) => {
  const response = await axiosApiInstance
    .post(BASE_URL + REPORT_B1T, payload)
    .catch(HttpException.create);
  return response.data;
};

export const fetchDevices = async (payload) => {
  const response = await axiosApiInstance
    .post(BASE_URL + REPORT_B2T, payload)
    .catch(HttpException.create);
  return response.data;
};

export const fetchFailedReasons = async (payload) => {
  const response = await axiosApiInstance
    .post(BASE_URL + REPORT_B3T, payload)
    .catch(HttpException.create);
  return response.data;
};

export const fetchListReasonsForFail = async (payload) => {
  const response = await axiosApiInstance
    .post(BASE_URL + LIST_FAILED_REASON, payload)
    .catch(HttpException.create);
  return response.data;
};
