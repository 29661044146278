import React, { useContext, useState } from "react";
import { Grid, TextField } from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { styled } from "@mui/system";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import InputAdornment from "@mui/material/InputAdornment";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { formatDateYYYYMMDD } from "../../utils/DateUtils";

import "dayjs/locale/en-gb";
import "dayjs/locale/en";
import ACTDropdownList from "../shared/ACTDropdownList";
import { AppContext } from "../../context/AppContext";
import ACTDropdownWithCollapseMenuItemList from "../shared/ACTDropdownWithCollapseMenuItemList";

var userLocale = Intl.DateTimeFormat().resolvedOptions().locale;
if (!userLocale) {
  userLocale = navigator.language || navigator.userLanguage;
}

const CustomInput = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    paddingRight: 0,
  },
  "& .MuiInputAdornment-root": {
    marginLeft: theme.spacing(1),
  },
}));

const DashboardHeaderMobile = (props) => {
  const {
    onTabSelect,
    onRefreshData,
    type,
    reasonsForFail,
    userApps,
    totalRecords,
  } = props;
  const [value, setValue] = useState(0);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const { dashboardStates, setDashboardData } = useContext(AppContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (onTabSelect) {
      onTabSelect(newValue);
    }
  };

  const handleDateChange = (newDate) => {
    if (newDate) {
      const formattedDate = formatDateYYYYMMDD(newDate);
      if (onRefreshData) {
        onRefreshData(formattedDate, type);
      }
    }
  };

  const refreshData = (newValue, type, key) => {
    if (onRefreshData) {
      onRefreshData(newValue, type);
      setDashboardData(key, newValue);
    }
  };

  return (
    <Grid
      sx={{ flexGrow: 1, justifyContent: "center" }}
      container
      spacing={2}
      style={{
        paddingTop: "1rem",
        paddingBottom: "1rem",
      }}
    >
      <Grid item xs={6} sm={4}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={userLocale.toLowerCase()}
        >
          <MobileDatePicker
            format={
              userLocale.toLowerCase() === "en-gb" ? "DD/MM/YYYY" : "MM/DD/YYYY"
            }
            defaultValue={dayjs()}
            sx={{
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
            InputProps={{
              sx: {
                "& .MuiPickersYear-yearButton": { textAlign: "center" },
              },
            }}
            slotProps={{
              textField: {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <ArrowDropDownIcon />
                    </InputAdornment>
                  ),
                  sx: {
                    textAlign: "right",
                    ".MuiInputBase-input": {
                      textAlign: "right",
                      margin: ".5rem",
                    },
                  },
                },
              },
            }}
            onChange={handleDateChange}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={6} sm={4}>
        {userApps && (
          <ACTDropdownList
            name={"user_apps"}
            options={userApps}
            title={"Select User Apps"}
            type={2}
            onRefreshData={refreshData}
            selectedItem={dashboardStates["user_apps"] || null}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default DashboardHeaderMobile;
