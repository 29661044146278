import { Link } from "react-router-dom";
import { formatDateBasedOnLocale } from "../../utils/DateUtils";
import { getValidationStatus } from "../../utils/CommonUtils";
import { Box } from "@mui/material";

export const getBlockedAccountDataGridColumns = (showMoreHandler, theme) => {
  return [
    {
      field: "AccountID",
      headerName: "Account ID",
      width: 180,
      display: "flex",
      renderCell: (cellValues) => {
        return (
          <Link
            to={"/summary/account/" + cellValues.row.AccountId}
            style={{ color: theme.palette.text.primary, textDecoration: "underline" }}
            state={{ type: "account", id: cellValues.row.AccountId }}
          >
            {cellValues.row.AccountId}
          </Link>
        );
      },
    },
    {
      field: "LastBlocked",
      headerName: "Blocked Date",
      width: 160,
      display: "flex",
      valueFormatter: (params) => {
        return formatDateBasedOnLocale(params);
      },
    },
    {
      field: "FirstSeen",
      headerName: "First Seen Date",
      width: 160,
      display: "flex",
      valueFormatter: (params) => {
        return formatDateBasedOnLocale(params);
      },
    },
    {
      field: "LastSeen",
      headerName: "Last Seen Date",
      width: 160,
      display: "flex",
      valueFormatter: (params) => {
        return formatDateBasedOnLocale(params);
      },
    },
    {
      field: "NoOfLookups",
      headerName: "Total Checks",
      width: 130,
      display: "flex",
    },
    {
      field: "Notes",
      headerName: "Notes",
      width: 200,
      display: "flex",
    },
    {
      field: "DeviceIds",
      headerName: "Device IDs",
      width: 350,
      display: "flex",
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", flexDirection: "row", gap: ".5em" }}>
            {params.row.DeviceIds.length > 0 && (
              <>
                <Link
                  to={"/summary/device/" + params.row.DeviceIds[0]}
                  style={{ color: theme.palette.text.primary, textDecoration: "underline" }}
                  state={{ type: "device", id: params.row.DeviceIds[0] }}
                >
                  {params.row.DeviceIds[0]}
                </Link>
                {params.row.DeviceIds.length > 1 && (
                  <div
                    style={{
                      textDecoration: "underline",
                      color: "GrayText",
                      cursor: "pointer",
                      color: theme.palette.text.primary
                    }}
                    onClick={(e) => showMoreHandler(e, params.row.DeviceIds)}
                  >
                    show more
                  </div>
                )}
              </>
            )}
            {/* {params.row.DeviceIds.map((deviceId, index) => {
              // return <div key={index}>{acc}</div>
              return (
                <div key={index} style={{ padding: '2px 0' }}>
                  <Link
                    to={"/summary/device/" + deviceId}
                    style={{ color: "#000000", textDecoration: "underline" }}
                    state={{ type: "device", id: deviceId }}
                  >
                    {deviceId}
                  </Link>
                </div>
              );
            })} */}
          </div>
        );
      },
    },
  ];
};
