import { Box, IconButton } from "@mui/material";
import { getReasonsNameByCode } from "../../utils/CommonUtils";
import { formatDateBasedOnLocale } from "../../utils/DateUtils";
import { CopyAllRounded } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import ACTTransactionsMultiple from "./ACTTransactionsMultiple";

const ACTTransaction = ({
  selectedGeoRefId,
  points,
  failedReasons,
  onCopyData,
  onViewSimilarLocations,
}) => {
  const result = points.find(
    (item) => item.GeoReferenceId === selectedGeoRefId
  );
  const latLngGroup = points.filter(
    (item) =>
      item.Latitude === result.Latitude && item.Longitude === result.Longitude
  );
  const theme = useTheme();
  const customtextColorForDarkmode = (theme) => ({
    color: theme.palette.mode === "dark" && "#ffffff",
  });

  return (
    <>
      {latLngGroup.length > 1 && (
        <>
          <ACTTransactionsMultiple
            selectedGeoRefId={selectedGeoRefId}
            points={points}
            failedReasons={failedReasons}
            onCopyData={onCopyData}
            latLngGroup={latLngGroup}
          />
        </>
      )}
      {latLngGroup.length === 1 && (
        <Box
          sx={{
            width: "90vw",
            maxWidth: "31.5rem",
            maxHeight: "21rem",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            gap: "0.15rem",
            paddingLeft: "0.75rem",
          }}
          style={customtextColorForDarkmode(theme)}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "1.2rem",
            }}
          >
            <div
              style={{
                marginRight: "0.2rem",
              }}
            >
              <strong>Date & Time:</strong>{" "}
            </div>
            <>{formatDateBasedOnLocale(result.Timestamp)}</>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "1.2rem",
            }}
          >
            <div
              sx={{
                fontSize: "0.8125rem",
                lineHeight: "1",
              }}
              style={customtextColorForDarkmode(theme)}
            >
              <strong>Device Type: </strong>
              {result.DeviceType}
            </div>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "1.2rem",
            }}
          >
            <div>
              <strong>Device ID: </strong>
              <Link
                to={"/summary/device/" + result.DeviceId}
                style={{
                  color: theme.palette.text.primary,
                  textDecoration: "underline",
                }}
              >
                {result.DeviceId}
              </Link>
            </div>
            <div>
              <IconButton
                aria-label="copy"
                size="small"
                onClick={() => onCopyData(result.DeviceId)}
              >
                <CopyAllRounded
                  sx={{
                    fontSize: "1.3rem",
                  }}
                  style={customtextColorForDarkmode(theme)}
                />
              </IconButton>
            </div>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "1.2rem",
            }}
          >
            <div>
              <strong>Account ID: </strong>
              <Link
                to={"/summary/account/" + result.AccountId}
                style={{
                  color: theme.palette.text.primary,
                  textDecoration: "underline",
                }}
              >
                {result.AccountId}
              </Link>
            </div>
            <div>
              <IconButton
                aria-label="copy"
                size="small"
                onClick={() => onCopyData(result.AccountId)}
              >
                <CopyAllRounded
                  sx={{
                    fontSize: "1.3rem",
                  }}
                  style={customtextColorForDarkmode(theme)}
                />
              </IconButton>
            </div>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "1.2rem",
            }}
          >
            <div>
              <strong>Lat & Long:</strong> {result.Latitude}, {result.Longitude}
            </div>
            <div>
              <IconButton
                aria-label="copy"
                size="small"
                onClick={() =>
                  onCopyData(result.Latitude + "," + result.Longitude)
                }
              >
                <CopyAllRounded
                  sx={{
                    fontSize: "1.3rem",
                  }}
                  style={customtextColorForDarkmode(theme)}
                />
              </IconButton>
            </div>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "1.2rem",
            }}
          >
            <div
              style={{
                marginRight: "0.2rem",
              }}
            >
              <strong>Status: </strong>{" "}
            </div>
            <>{getReasonsNameByCode(result.ReasonForFailure[0], failedReasons)} </>

            <br></br>
          </Box>

          <br></br>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "1.2rem",
              marginBottom: "0.25rem",
            }}
          >
            <Link
              to={"/summary/device/" + result.DeviceId}
              state={{ type: "device", id: result.DeviceId }}
            >
              {"view all device activity"}
            </Link>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ACTTransaction;
