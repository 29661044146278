import { React, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import FormatSizeIcon from "@mui/icons-material/FormatSize";
import ImageIcon from "@mui/icons-material/Image";
import { Button, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AppContext } from "../../context/AppContext";
import geosafeLogo from "../../assets/images/geosafe-logo.png";
import geosafeLogoWhite from "../../assets/images/geosafe-logo-white.png";

import "./SideMenuDrawerStyles.scss";
import { SEARCH_CRITERIA_LIST } from "../../constants/Constants";
import ACTEditSavedReportDialog from "../shared/ACTEditSavedReportDialog";

const SIDE_MENU_DATA = [
  {
    name: "Dashboard",
    icon: <DashboardIcon className="link-icon" />,
    link: "/dashboard",
  },
  {
    name: "Users",
    icon: <GroupIcon className="link-icon-disabled" />,
    link: "/users",
  },
  {
    name: "Reports",
    icon: <ShoppingBasketIcon className="link-icon" />,
    link: "/reports",
  },
  {
    name: "Glossary",
    icon: <FormatSizeIcon className="link-icon" />,
    link: "/glossary",
  },
  {
    name: "Contact Support",
    icon: <ImageIcon className="link-icon-disabled" />,
    link: "/contact-support",
  },
];

const SAVED_REPORT_DATA = [
  {
    name: "Last 7 Days",
    icon: <CalendarTodayIcon className="link-icon" />,
    link: "/last-seven-days",
    search: null,
  },
  {
    name: "Last 30 Days",
    icon: <CalendarTodayIcon className="link-icon" />,
    link: "/last-thirty-days",
    search: null,
  },
];

const SideMenuDrawer = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const isDesktop = useMediaQuery(theme.breakpoints.up("md")); // true if screen is md (medium) or larger
  const location = useLocation();

  const [savedReports, setSavedReports] = useState(SAVED_REPORT_DATA);
  const [selectedSearchMenuItem, setSelectedSearchMenuItem] = useState(null);

  const onSideMenuItemClick = (path) => {
    navigate(path);
    if (!isDesktop) {
      appContext.setDrawerOpen(false);
    }
  };

  const onSideMenuSavedSearchItemClick = (item) => {
    if (item.search) {
      navigate(item.link, {
        state: { key: item.key, searchParam: item.search, advanceSearch: true },
      });
    } else {
      navigate(item.link);
    }
    if (!isDesktop) {
      appContext.setDrawerOpen(false);
    }
  };

  const [items, setItems] = useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("items"));
    if (items) {
      setItems(items);
    }
    const searchCriteriaList = localStorage.getItem(SEARCH_CRITERIA_LIST);
    if (searchCriteriaList) {
      const list = JSON.parse(searchCriteriaList);
      const searchMenuList = list.map((item) => {
        return {
          name: item.title,
          icon: <CalendarTodayIcon className="link-icon" />,
          link: "/search?" + item.key,
          search: item.search,
          key: item.key,
        };
      });
      const allSearchMenuList = [...SAVED_REPORT_DATA, ...searchMenuList];
      setSavedReports(allSearchMenuList);
    }
  }, [appContext.refreshSavedSearchSideMenu]);

  const [showEditSavedReportDialog, setShowEditSavedReportDialog] =
    useState(false);

  const openEditSavedSearchDialog = (item) => {
    setSelectedSearchMenuItem(item);
    setShowEditSavedReportDialog(true);
  };

  const onEditReportName = (searchItem, updatedTitle) => {
    const searchCriteriaList = localStorage.getItem(SEARCH_CRITERIA_LIST);
    if (searchCriteriaList) {
      const list = JSON.parse(searchCriteriaList);

      const searchMenuList = list.map((item) => {
        if (item.title === searchItem.name) {
          return {
            name: updatedTitle,
            icon: <CalendarTodayIcon className="link-icon" />,
            link: "/search?" + item.key,
            search: item.search,
            key: item.key,
          };
        } else {
          return {
            name: item.title,
            icon: <CalendarTodayIcon className="link-icon" />,
            link: "/search?" + item.key,
            search: item.search,
            key: item.key,
          };
        }
      });
      const allSearchMenuList = [...SAVED_REPORT_DATA, ...searchMenuList];
      setSavedReports(allSearchMenuList);
      const json = JSON.parse(searchCriteriaList);
      const saveSearchInLocalStorageList = json.map((item) => {
        if (item.title === searchItem.name) {
          return { ...item, title: updatedTitle };
        } else {
          return item;
        }
      });
      localStorage.setItem(
        SEARCH_CRITERIA_LIST,
        JSON.stringify(saveSearchInLocalStorageList)
      );
    }
  };

  const onDeleteReport = (searchItem) => {
    const searchCriteriaList = localStorage.getItem(SEARCH_CRITERIA_LIST);
    if (searchCriteriaList) {
      const list = JSON.parse(searchCriteriaList);

      const searchMenuList = list.filter((item) => {
        if (item.title !== searchItem.name) {
          return item;
        }
      });
      const saveSearchInLocalStorageList = searchMenuList.map((item) => {
        return {
          name: item.title,
          icon: <CalendarTodayIcon className="link-icon" />,
          link: "/search?" + item.key,
          search: item.search,
          key: item.key,
        };
      });
      if (searchMenuList.length > 0) {
        const allSearchMenuList = [
          ...SAVED_REPORT_DATA,
          ...saveSearchInLocalStorageList,
        ];
        setSavedReports(allSearchMenuList);
      } else {
        const allSearchMenuList = [...SAVED_REPORT_DATA];
        setSavedReports(allSearchMenuList);
      }
      localStorage.setItem(
        SEARCH_CRITERIA_LIST,
        JSON.stringify(searchMenuList)
      );
    }
  };

  return (
    <>
      <div className="nav-menu-top">
        <div className="nav-side-bar"></div>
        <img
          src={theme.palette.mode === "dark" ? geosafeLogoWhite : geosafeLogo}
          alt="Geosafe logo"
          className="side-logo"
        />
      </div>

      <List>
        {SIDE_MENU_DATA.map((item, index) => (
          <ListItem
            key={index}
            disablePadding
            className={item.link === location.pathname ? "active-link" : ""}
          >
            <ListItemButton
              onClick={() => onSideMenuItemClick(item.link)}
              disabled={
                index === 0 || index === 2 || index === 3
                  ? false
                  : true
              }
            >
              <ListItemIcon sx={{ justifyContent: "center" }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.name}
                className={
                  index === 0 || index === 2 || index === 3
                    ? ""
                    : "disabled-colour"
                }
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <div className="nav-reports-list">
        <p
          className="nav-reports-subhead"
          style={{ color: theme.palette.text.primary }}
        >
          Saved Reports
        </p>
      </div>
      <List>
        {savedReports.map((item, index) => (
          <ListItem
            key={index}
            disablePadding
            className={
              item.link === location.pathname ||
              "?" + item.key === location.search
                ? "active-link"
                : ""
            }
          >
            <ListItemButton
              onClick={() => onSideMenuSavedSearchItemClick(item)}
            >
              <ListItemIcon
                sx={{
                  justifyContent: "center",
                  color: "#aaaaaa",
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
            <button
              onClick={() => openEditSavedSearchDialog(item)}
              sx={{
                justifyContent: "right",
                width: "10",
                color: "#aaaaaa",
              }}
            >
              {item.edit}
            </button>
          </ListItem>
        ))}
      </List>
      <ACTEditSavedReportDialog
        openDialog={showEditSavedReportDialog}
        closeHandler={setShowEditSavedReportDialog}
        selectedSearchItem={selectedSearchMenuItem}
        saveEditSearchHandler={onEditReportName}
        deleteSavedReportHandler={onDeleteReport}
      />
    </>
  );
};

export default SideMenuDrawer;
