import React, { useContext, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import TopInfoBar from "../topbar/TopInfoBar";
import SegmentIcon from "@mui/icons-material/Segment";
import SideMenuDrawer from "../sidebar/SideMenuDrawer";
import { AppContext } from "../../context/AppContext";
import { DrawerHeader } from "./DrawerHeader";
import { DRAWER_WIDTH } from "../../constants/Constants";
import CssBaseline from '@mui/material/CssBaseline';
import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';

import "../sidebar/SideMenuDrawerStyles.scss";

const MainLayout = ({ toggleDarkMode }) => {
  const theme = useTheme();
  const appContext = useContext(AppContext);
  const isDesktop = useMediaQuery(theme.breakpoints.up('md')); // true if screen is md (medium) or larger
  const handleDrawerOpen = () => {
    appContext.setDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    appContext.setDrawerOpen(false);
  };

  useEffect(() => {
    if(isDesktop){
      appContext.setDrawerOpen(true);
    }else{
      appContext.setDrawerOpen(false);
    }
  }, [isDesktop])

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <TopInfoBar 
      handleDrawerOpen={handleDrawerOpen}
      // darkMode={darkMode}
      toggleDarkMode={toggleDarkMode}
      />
      <Drawer
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: DRAWER_WIDTH,
            boxSizing: "border-box",
          },
        }}
        variant={'persistent'}
        // variant={isDesktop ? 'persistent' : 'temporary'}

        anchor="left"
        open={appContext.drawerOpen}
      >
        <DrawerHeader
          style={{
            backgroundColor: "#1e86f0",
            justifyContent: "space-between",
          }}
        >
          <div>
            <p className="side-nav-top-head">ADMIN</p>
          </div>
          <IconButton onClick={handleDrawerClose}>
            <SegmentIcon sx={{ color: "#ffffff" }} />
          </IconButton>
        </DrawerHeader>
        <SideMenuDrawer />
      </Drawer>
    </Box>
  );
};

export default MainLayout;
