import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import Users from "../pages/Users";
import Reports from "../pages/Reports";
import Glossary from "../pages/Glossary";
import ContactSupport from "../pages/ContactSupport";
import Summary from "../pages/Summary"
import Search from "../pages/Search";
import BlockedAccounts from "../pages/BlockedAccounts";
import BlockedDevices from "../pages/BlockedDevices";
import SavedReport from "../pages/SavedReport";
import VPNDetected from "../pages/VPNDetected";

const AppRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Navigate to="/dashboard" />} />
      <Route exact path="/login" element={<Navigate to="/dashboard" />} />
      <Route path="/dashboard" element={<Home />} />
      <Route path="/summary/:page" element={<Summary />} />
      <Route path="/summary/:id/:id" element={<Summary />} />
      <Route path="/users" element={<Users />} />
      <Route path="/reports" element={<Reports />} />
      <Route path="/glossary" element={<Glossary />} />
      <Route path="/contact-support" element={<ContactSupport />} />
      <Route path="/search" element={<Search />} />
      <Route path="/last-seven-days" element={<SavedReport />} />
      <Route path="/last-thirty-days" element={<SavedReport />} />
      <Route path="/blocked-accounts" element={<BlockedAccounts />} />
      <Route path="/blocked-devices" element={<BlockedDevices />} />
      <Route path="/vpn-detected" element={<VPNDetected />} />
    </Routes>
  );
};

export default AppRoutes;
