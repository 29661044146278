import { Link } from "react-router-dom";
import { formatDateBasedOnLocale } from "../../utils/DateUtils";

export const getBlockedDeviceDataGridColumns = (showMoreHandler, theme) => {
  return [
    {
      field: "DeviceId",
      headerName: "Device ID",
      width: 320,
      display: "flex",
      renderCell: (cellValues) => {
        return (
          <Link
            to={"/summary/device/" + cellValues.row.DeviceId}
            style={{ color: theme.palette.text.primary, textDecoration: "underline" }}
            state={{ type: "device", id: cellValues.row.DeviceId }}
          >
            {cellValues.row.DeviceId}
          </Link>
        );
      },
    },
    {
      field: "LastBlocked",
      headerName: "Blocked Date",
      width: 160,
      display: "flex",
      valueFormatter: (params) => {
        return formatDateBasedOnLocale(params);
      },
    },
    {
      field: "FirstSeen",
      headerName: "First Seen Date",
      width: 160,
      display: "flex",
      valueFormatter: (params) => {
        return formatDateBasedOnLocale(params);
      },
    },
    {
      field: "LastSeen",
      headerName: "Last Seen Date",
      width: 160,
      display: "flex",
      valueFormatter: (params) => {
        return formatDateBasedOnLocale(params);
      },
    },
    {
      field: "NoOfLookups",
      headerName: "Total Checks",
      width: 130,
      display: "flex",
    },
    {
      field: "Notes",
      headerName: "Notes",
      width: 200,
      display: "flex",
    },
    {
      field: "AccountIds",
      headerName: "Account IDs",
      width: 200,
      display: "flex",
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", flexDirection: "row", gap: ".5em" }}>
            {params.row.AccountIds.length > 0 && (
              <>
                <Link
                  to={"/summary/account/" + params.row.AccountIds[0]}
                  style={{ color: theme.palette.text.primary, textDecoration: "underline" }}
                  state={{ type: "account", id: params.row.AccountIds[0] }}
                >
                  {params.row.AccountIds[0]}
                </Link>
                {params.row.AccountIds.length > 1 && (
                  <div
                    style={{
                      textDecoration: "underline",
                      color: "GrayText",
                      cursor: "pointer",
                    }}
                    onClick={(e) => showMoreHandler(e, params.row.AccountIds)}
                  >
                    show more
                  </div>
                )}
              </>
            )}
            {/* {params.row.AccountIds.map((accountId, index) => {
              // return <div key={index}>{acc}</div>
              return (
                <Link
                  key={index}
                  to={"/summary/account/" + accountId}
                  style={{ color: "#000000", textDecoration: "underline" }}
                  state={{ type: "account", id: accountId }}
                >
                  {accountId}
                </Link>
              );
            })} */}
          </div>
        );
      },
    },
  ];
};
