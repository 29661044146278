import React from 'react';
import "./CloseButtonFilledStyles.scss";
import CloseIcon from "@mui/icons-material/Close";

const CloseButtonFilled = props => {
  
    return (
      <button
        className={"btn-enable-filled"}
        onClick={props.clickHandler}
        type={props.type}
        disabled={props.isDisabled}
      >
        <CloseIcon className="close-icon-filled" />
      </button>
    );
  };
  
  CloseButtonFilled.defaultProps = {
    type: "button",
    disabled: true
  };

export default CloseButtonFilled