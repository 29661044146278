import React, { useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { AppContext } from "../../../../context/AppContext";
import DialogSummaryMap from "./DialogSummaryMap";

export default function MapSummaryDialog(props) {
  const { open, onClose, params } = props;
  const theme = useTheme();
  const appContex = useContext(AppContext);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={false}
        PaperProps={{
          sx: {
            width: "85vw",
            height: "80vh",
            maxWidth: "none",
            maxHeight: "none",
            boxShadow: "none",
            position: "relative",
          },
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            padding: "0",
          }}
        >
          <DialogContent
            sx={{
              flexGrow: 1,
              overflow: "hidden",
              "&.MuiDialogContent-root": {
                padding: "0",
              },
            }}
          >
            <Box
              sx={{
                position: "absolute",
                zIndex: "1",
                height: "5rem",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0 1.5rem",
                backgroundColor:
                  theme.palette.mode === "dark" ? "#1c1c1c" : "#ffffff",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    lineHeight: "1.4",
                    fontWeight: "400",
                    color: theme.palette.text.primary,
                  }}
                >
                  {appContex.selectedUserApp.AppName}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    lineHeight: "1.4",
                    fontWeight: "600",
                    color: theme.palette.text.primary,
                  }}
                >
                  {params.Latitude + ", " + params.Longitude}
                </Typography>
              </Box>
              <button>
                <CloseIcon
                  onClick={onClose}
                  sx={{
                    cursor: "pointer",
                    marginTop: "0.4rem",
                    color: theme.palette.text.primary,
                  }}
                />
              </button>
            </Box>
            <Box
              sx={{
                height: "100%",
                width: "100",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DialogSummaryMap data={params} />
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
