import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField, Typography } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { useTheme } from "@emotion/react";

export default function ACTSavedReportDialog(props) {
  const { openDialog, closeHandler, saveSearchHandler } = props;
  const [title, setTitle] = useState("");
  const [isFilledTitle, setIsFilledTitle] = useState(false);

  const handleClose = () => {
    closeHandler(false);
  };

  const saveReportTitle = () => {
    closeHandler(false);
    if (saveSearchHandler) {
      saveSearchHandler(title);
    }
  };

  const onEnterTitle = (title) => {
    if (title.length === 0) {
      setIsFilledTitle(false);
    } else {
      setIsFilledTitle(true);
    }
    setTitle(title);
  };

  const theme = useTheme();

  return (
    <React.Fragment>
      <Dialog
        sx={{
          "& .MuiDialogContent-root": {
            padding: "1.25rem 2.5rem",
          },
          "& .MuiPaper-root": {
            width: "90vw",
            maxWidth: "23.438rem",
          },
        }}
        open={openDialog}
        onClose={handleClose}
      >
        <GridCloseIcon
          onClick={handleClose}
          sx={{
            height: "1.2rem",
            position: "absolute",
            right: "1rem",
            top: "1rem",
            cursor: "pointer",
          }}
        />
        <DialogTitle
          sx={{
            padding: "2.5rem 2rem 0rem 2.5rem",
            fontSize: "1rem",
            fontWeight: "700",
          }}
        >
          {"Enter report name"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              fontSize: "0.8rem",
              fontWeight: "500",
              padding: "0",
            }}
          >
            Please add a name for saved report
          </DialogContentText>
          <TextField
            sx={{
              margin: "2rem 0 0 0",
              width: "100%",
              "& .MuiInputBase-root": {
                fontSize: 14,
              },
            }}
            className="textField"
            type="text"
            id="reportName"
            name="reportName"
            value={title}
            onChange={(e) => onEnterTitle(e.target.value)}
            autoFocus
            multiline
            required
            fullWidth
            inputProps={{ maxLength: 15 }}
            rows={1}
            placeholder="Report name"
          />
          <Typography
            sx={{
              fontSize: "0.6rem",
              fontWeight: "400",
              paddingTop: "0.5rem",
              color: theme.palette.text.primary,
            }}
          >
            Max 15 characters
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            padding: "1rem 1.5rem 2.25rem 1.5rem",
          }}
        >
          <Button
            sx={{
              fontSize: "0.7rem",
              fontWeight: "600",
            }}
            onClick={handleClose}
          >
            cancel
          </Button>
          <Button
            disabled={!isFilledTitle}
            type="submit"
            sx={{
              fontSize: "0.7rem",
              fontWeight: "600",
            }}
            onClick={saveReportTitle}
          >
            save
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
