import axios from "axios";
import { BASE_URL } from "../constants/APIConstants";

export const axiosApiInstance = axios.create({ baseURL: BASE_URL });

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    config.headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      // "Cache-Control": "no-store",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);
