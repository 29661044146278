import * as React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { styled } from "@mui/material/styles";

const size = {
  height: 170,
  width: 170
};

// center text top
const StyledText = styled("text")(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 12,
  fontWeight: "normal",
}));

// center text bottom
const StyledTextTotal = styled("text")(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "bottom",
  fontSize: 22,
  fontWeight: "bold",
}));

// center text top
function PieCenterLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2.45}>
      {children}
    </StyledText>
  );
}

// center text bottom
function PieCenterLabelTotal({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledTextTotal x={left + width / 2} y={top + height / 1.75}>
      {children}
    </StyledTextTotal>
  );
}

const ACTPieChart = (props) => {
  const { data } = props;

  const getTotalValue = (data) => {
    return data.reduce((acc, item) => acc + item?.value, 0);
  };

  return (
    <PieChart
      margin={{ left: 0, right: 0, top: 0, bottom: 0 }}

      series={[{ data: data, innerRadius: 47, outerRadius: 75 }]}
      {...size}
    >
      {data && data.length > 0 && (
        <>
          <PieCenterLabel>Total</PieCenterLabel>
          <PieCenterLabelTotal>{getTotalValue(data)}</PieCenterLabelTotal>
        </>
      )}
    </PieChart>
  );
};

export default ACTPieChart;
