import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const Loader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        top: "50%",
        left: "50%",
        position: "fixed",
        zIndex: 1300,
      }}
    >
      <CircularProgress size={80} color="secondary" />
    </Box>
  );
};

export default Loader;
