import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { GridCloseIcon } from "@mui/x-data-grid";
import { TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";

export default function ACTEditSavedReportDialog(props) {
  const {
    openDialog,
    closeHandler,
    selectedSearchItem,
    saveEditSearchHandler,
    deleteSavedReportHandler,
  } = props;
  const [title, setTitle] = useState("");
  const [deletedSearch, setDeletedSearch] = useState("");
  const [isEditedTitle, setIsEditedTitle] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedSearchItem) {
      setTitle(selectedSearchItem.name);
    }
  }, [selectedSearchItem]);

  useEffect(() => {
    if (selectedSearchItem) {
      setDeletedSearch(selectedSearchItem);
    }
  }, [selectedSearchItem]);

  const handleClose = () => {
    closeHandler(false);
    resetDeleteButton();
  };

  const saveEditedReportTitle = () => {
    closeHandler(false);
    resetDeleteButton();
    if (saveEditSearchHandler) {
      saveEditSearchHandler(selectedSearchItem, title);
    }
  };

  const onEditTitle = (title) => {
    if (title.length === 0) {
      setIsEditedTitle(false);
    } else {
      setIsEditedTitle(true);
    }
    setTitle(title);
  };

  const deleteReport = () => {
    if (deleteSavedReportHandler) {
      deleteSavedReportHandler(selectedSearchItem, deletedSearch);
    }
    closeHandler(false);
    resetDeleteButton();
    navigate("/last-seven-days");
  };

  const resetDeleteButton = () => {
    setTimeout(function resetDelete() {
      setIsDeleteOpen(false);
    }, 500);
  };

  const showDeleteConfirmButton = () => {
    if (isDeleteOpen === true) {
      setIsDeleteOpen(false);
    } else if (isDeleteOpen === false) {
      setIsDeleteOpen(true);
    }
  };

  const theme = useTheme();

  return (
    <React.Fragment>
      <Dialog
        sx={{
          "& .MuiDialogContent-root": {
            padding: "1.25rem 2.5rem",
          },
          "& .MuiPaper-root": {
            width: "90vw",
            maxWidth: "23.438rem",
          },
        }}
        open={openDialog}
        onClose={handleClose}
      >
        <GridCloseIcon
          onClick={handleClose}
          sx={{
            height: "1.2rem",
            position: "absolute",
            right: "1rem",
            top: "1rem",
            cursor: "pointer",
          }}
        />
        <DialogTitle
          sx={{
            padding: "2.5rem 2rem 0rem 2.5rem",
            fontSize: "1rem",
            fontWeight: "700",
          }}
        >
          {"Edit report name"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              fontSize: "0.8rem",
              fontWeight: "500",
              padding: "0 0 0 0",
            }}
          >
            Please edit name for saved report
          </DialogContentText>
          <TextField
            sx={{
              margin: "2rem 0 0 0",
              width: "100%",
              color: theme.palette.text.primary,
              "& .MuiInputBase-root": {
                fontSize: 14,
              },
            }}
            className="textField"
            type="text"
            id="reportName"
            name="reportName"
            value={title}
            onChange={(e) => onEditTitle(e.target.value)}
            autoFocus
            multiline
            required
            fullWidth
            inputProps={{ maxLength: 15 }}
            rows={1}
            placeholder=""
          />
          <Typography
            sx={{
              fontSize: "0.6rem",
              fontWeight: "400",
              paddingTop: "0.5rem",
              color: theme.palette.text.primary,
            }}
          >
            Max 15 characters
          </Typography>
        </DialogContent>
        <DialogActions
         sx={{
          padding: "0rem 1.5rem 2.25rem 1.5rem",
        }}
        >
          <Button
            sx={{
              fontSize: "0.7rem",
              fontWeight: "600",
            }}
            onClick={handleClose}
          >
            cancel
          </Button>
          <Button
            disabled={!isEditedTitle}
            sx={{
              fontSize: "0.7rem",
              fontWeight: "600",
            }}
            onClick={saveEditedReportTitle}
            autoFocus
          >
            save
          </Button>
        </DialogActions>
        {!isDeleteOpen ? (
          <button
            className="delete-button"
            aria-hidden="false"
            onClick={showDeleteConfirmButton}
          >
            delete report
          </button>
        ) : (
          <button
            className="delete-button-confirm"
            aria-hidden="false"
            onClick={deleteReport}
          >
            confirm delete
          </button>
        )}
      </Dialog>
    </React.Fragment>
  );
}
