import { Alert } from "@mui/material";
import React, { useState } from "react";
// import CloseButtonPlain from "../Buttons/CloseButtonPlain/CloseButtonPlain";
import "./ErrorAlertStyles.scss";

const ErrorAlert = (props) => {
  const { info } = props;

  /** function to close warning panel */
  const [isAlertOpen, setIsAlertOpen] = useState(true);
  function closeAlertClick() {
    setIsAlertOpen(false);
  }

  return (
    <>
      {isAlertOpen && (
        <Alert
          sx={{
            border: "#d74143 0.1rem solid",
            position: "relative",
            marginTop: "0.25rem",
            marginBottom: "0.75rem",
          }}
          severity="error"
        >
          <div className="alert-block">
            {info} 
            {/* <div className="close-icon-container">
              <CloseButtonPlain
                type={"Submit"}
                isDisabled={false}
                clickHandler={closeAlertClick}
              />
            </div> */}
          </div>
        </Alert>
      )}
    </>
  );
};

export default ErrorAlert;
