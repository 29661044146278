import React, { useContext, useEffect, useState } from "react";
import { DrawerHeader } from "../components/sidebar/DrawerHeader";
import { AppContext } from "../context/AppContext";
import { Main } from "../components/shared/Main";
import "../components/glossary/GlossaryStyles.scss";
import GlossaryButton from "../components/shared/Buttons/GlossaryButton";
import "../assets/data/glossary.json";

import glossaryData from "../assets/data/glossary.json";
import { Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';

const goToLetter = (index) => {
  document.getElementById("section" + index).scrollIntoView();
};

const Glossary = () => {

  const appContext = useContext(AppContext);
  const theme = useTheme();
  
  return (
    <Main open={appContext.drawerOpen} >
      <DrawerHeader />
      <div className="page-container" style={{
        width: "100%",
        backgroundColor: theme.palette.mode === 'dark' ? '#1c1c1c' : '#d8d8d8'
      }}>
        <div className="page-content-container">
          <p className="page-header" style={{ color: theme.palette.text.primary }}>{glossaryData.title}</p>

          <div className="glossary-nav-container" style={{
            width: "100%",
            backgroundColor: theme.palette.mode === 'dark' ? '#1c1c1c' : '#d8d8d8'
          }}>
            {glossaryData.indices.map(
              (navLetters, index) => (
                (
                  <GlossaryButton
                    key={index}
                    variant="outlined"
                    onClick={() => goToLetter(index)}
                  >
                    <p key={index} style={{ color: theme.palette.text.primary }}>{navLetters}</p>
                  </GlossaryButton>
                )
              )
            )}
          </div>

          <div className="glossary-list">
            {glossaryData.sections.map((section, index) => {
              return (
                <section key={index} id={"section" + index}>
                  <div className="glosary-list-subhead">{section.header}</div>
                  <div className="glossary-list-text-panel">
                    {section.items.map((item, index) => {
                      return (
                        <div key={index}>
                          <p className="glossary-list-text-subhead" style={{ color: theme.palette.text.primary }}>
                            {item.title}
                          </p>
                          <p className="glossary-list-text" style={{ color: theme.palette.text.primary }}>
                            {item.description}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </section>
              );
            })}
          </div>
        </div>
      </div>
    </Main>
  );
};

export default Glossary;
