import React, { useCallback } from "react";
import {
  AdvancedMarker,
  useAdvancedMarkerRef,
  Pin,
} from "@vis.gl/react-google-maps";
import MultiPin from '../../../../../src/assets/images/MultiPin.svg'

const FeatureMarker = (props) => {
  const { position, onMarkerClick, item, bgColor, label, isGrouped } = props;

  const [markerRef, marker] = useAdvancedMarkerRef();

  const handleClick = useCallback(
    () => onMarkerClick && onMarkerClick(marker, item),
    [onMarkerClick, marker, item]
  );

  // Custom content for grouped marker with a "+" icon overlay
  const GroupedPinContent = () => {
    return (
      <div style={{
        position: 'relative',
        width: '29px',
        height: '35px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent',
      }}>

        <img
          src={MultiPin}
          alt="Grouped Pin"
          style={{
            width: '100%',
            height: '100%',
            imageRendering: 'crisp-edges'
          }}
        />
      </div>
    );
  };

  return (
    <AdvancedMarker
      key={item?.GeoReferenceId}
      ref={markerRef}
      position={position}
      onClick={handleClick}
      label={label} // Display the label if it's a grouped marker
      content={MultiPin}
    >
      {isGrouped ? <span><GroupedPinContent /></span> :
        <Pin
          background={bgColor}
          glyphColor={"#ffffff"}
          borderColor={"#ffffff"}
        />
      }
    </AdvancedMarker>
  );
};

export default FeatureMarker;
