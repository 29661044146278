import { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from "material-react-toastify";

import { getAllBlockedDevices } from "../../services/BlockedAccountAndDeviceService";
import { Box, Popover, Typography } from "@mui/material";
import CommonListView from "../commonListView/CommonListView";
import { AppContext } from "../../context/AppContext";
import {
  showCatchedExceptionOnToast,
  validateAnsShowServerException,
} from "../../utils/ExceptionUtils";
import Loader from "../loader/Loader";
import { getBlockedDeviceDataGridColumns } from "./BlockedDeviceDataGridColumns";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";
import DeviceImage from "../../assets/images/search-devices.png";
import GenericError from "../GenericError/GenericError";

const noDataReasons = {
  title:
    "Currently no blocked device data.",
  image: DeviceImage,
};

const BlockedDevicesContainer = () => {
  const appContext = useContext(AppContext);
  const { userDetails, selectedUserApp } = appContext;
  const [showLoader, setShowLoader] = useState(false);
  const [blockedDevices, setBlockedDevices] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [moreItems, setMoreItems] = useState(null);
  const theme = useTheme();
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    fetchBlockedAccounts();
  }, []);

  const fetchBlockedAccounts = () => {
    try {
      const payload = {
        JWTid: userDetails.JWTid,
        appId: selectedUserApp.AppId,
      };
      setShowLoader(true);
      getAllBlockedDevices(payload)
        .then((response) => {
          if (response.Status === 0) {
            setBlockedDevices(response);
          } else {
            validateAnsShowServerException(
              toast,
              2,
              response.Status,
              appContext
            );
          }
        })
        .catch((err) => {
          showCatchedExceptionOnToast(toast, 2, err);
        })
        .finally(() => {
          setShowLoader(false);
        });
    } catch (err) {
      showCatchedExceptionOnToast(toast, 2, err);
    }
  };

  const onShowMoreClick = (e, items) => {
    setAnchorEl(e.currentTarget);
    setMoreItems(items);
  };

  return (
    <>
      <Typography
        sx={{
          fontSize: "1.3rem",
          fontWeight: "500",
          marginBottom: ".5rem",
          color: theme.palette.text.primary 
        }}
      >
        Blocked Devices
      </Typography>
      {blockedDevices && blockedDevices.Data.devicesBlockedPerApps[0].Details.length > 0 && (
        <Box
          sx={{
            bgcolor: "#ffffff",
            borderRadius: "5px",
          }}
        >
          <CommonListView
            rows={blockedDevices.Data.devicesBlockedPerApps[0].Details}
            columns={getBlockedDeviceDataGridColumns(onShowMoreClick, theme)}
            rowCount={
              blockedDevices.Data.devicesBlockedPerApps[0].Details.length
            }
            pageSize={100}
            // onRefreshData={(paginationModel) =>
            //   onRefreshData(paginationModel, 2)
            // }
            getRowId={(row) => row.DeviceId}
            // height={tabIndex === 1 ? "70vh" : "50vh"}
          />
        </Box>
      )}
      {blockedDevices && blockedDevices.Data.devicesBlockedPerApps[0].Details.length === 0 && (
        <GenericError data={noDataReasons} />
      )}
      {showLoader && <Loader />}
      <ToastContainer position="top-center" autoClose={3000} />
      {moreItems && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <div
            style={{ display: "flex", margin: "1rem", flexDirection: "column" }}
          >
            {moreItems.map((item, index) => {
              return (
                <Link
                  key={index}
                  to={"/summary/account/" + item}
                  style={{ color: theme.palette.text.primary, textDecoration: "underline" }}
                  state={{ type: "account", id: item }}
                >
                  {item}
                </Link>
              );
            })}
          </div>
        </Popover>
      )}
    </>
  );
};

export default BlockedDevicesContainer;
