import React, { useCallback } from "react";
import { AdvancedMarker, Pin } from "@vis.gl/react-google-maps";

/**
 * Wrapper Component for an AdvancedMarker for a single pin.
 */
export const ACTMarkers = (props) => {
  const { point, onClick, setMarkerRef } = props;
  const handleClick = useCallback(() => onClick(point), [onClick, point]);
  const ref = useCallback(
    (marker) => setMarkerRef(marker, point.key),
    [setMarkerRef, point.key]
  );

  return (
    <AdvancedMarker position={point.position} ref={ref} onClick={handleClick}>
      <Pin
        background={point.color}
        glyphColor={"#ffffff"}
        borderColor={"#ffffff"}
      />
    </AdvancedMarker>
  );
};
