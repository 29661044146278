import { InfoWindow, useMap } from "@vis.gl/react-google-maps";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { ACTMarkers } from "./ACTMarkers";
import { useTheme } from '@mui/material/styles';
import ACTTransaction from "../../../shared/ACTTransaction";

export const LocationTabClusteredMarkers = (props) => {
  const { points, reasonsForFail, summaryType, summaryId } = props;
  const [markers, setMarkers] = useState({});
  const [selectedPinKey, setSelectedPinKey] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [moreItems, setMoreItems] = useState(null);
  const theme = useTheme();
  const customtextColorForDarkmode = (theme) => ({
    color: theme.palette.mode === 'dark' && '#ffffff',
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const selectedPin = useMemo(
    () =>
      points && selectedPinKey
        ? points.find((t) => t.GeoReferenceId === selectedPinKey)
        : null,
    [points, selectedPinKey]
  );

  const getColorNumPoints = (count) => {
    if (count > 30)
      return window.btoa(
        '<svg fill="#ff6d00" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240"><circle cx="120" cy="120" opacity="1" r="70" /><circle cx="120" cy="120" opacity=".3" r="90" /><circle cx="120" cy="120" opacity=".2" r="110" /><circle cx="120" cy="120" opacity=".1" r="130" /></svg>'
      );
    else {
      return window.btoa(
        '<svg fill="#ffa726" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240"><circle cx="120" cy="120" opacity="1" r="70" /><circle cx="120" cy="120" opacity=".3" r="90" /><circle cx="120" cy="120" opacity=".2" r="110" /><circle cx="120" cy="120" opacity=".1" r="130" /></svg>'
      );
    }
  };

  const renderer = {
    render: ({ count, position }) => {
      return new window.google.maps.Marker({
        icon: {
          url: "data:image/svg+xml;base64," + getColorNumPoints(count),
          scaledSize: new window.google.maps.Size(55, 55),
        },
        label: { text: String(count), color: "white", fontSize: "10px" },
        position,
        // adjust zIndex to be above other markers
        zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count,
      });
    },
  };

  // create the markerClusterer once the map is available and update it when
  // the markers are changed
  const map = useMap();
  const clusterer = useMemo(() => {
    if (!map) return null;

    return new MarkerClusterer({ map, renderer });
  }, [map]);

  useEffect(() => {
    if (!clusterer) return;

    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));
  }, [clusterer, markers]);

  // this callback will effectively get passsed as ref to the markers to keep
  // tracks of markers currently on the map
  const setMarkerRef = useCallback((marker, key) => {
    setMarkers((markers) => {
      if ((marker && markers[key]) || (!marker && !markers[key]))
        return markers;

      if (marker) {
        return { ...markers, [key]: marker };
      } else {
        const { [key]: _, ...newMarkers } = markers;

        return newMarkers;
      }
    });
  }, []);

  const handleInfoWindowClose = useCallback(() => {
    setSelectedPinKey(null);
  }, []);

  const handleMarkerClick = useCallback((point) => {
    setSelectedPinKey(point.key);
  }, []);

  const getColorByReasonCode = (reasonCode) => {
    if (!reasonsForFail) return "#9dc5ed";

    const itemFound = reasonsForFail.find(
      (reasonItem) => reasonItem.id === reasonCode
    );
    return itemFound ? itemFound.color : "#9dc5ed";
  };

  const getNameByReasonCode = (reasonCode) => {
    if (!reasonsForFail) return null;

    const itemFound = reasonsForFail.find(
      (reasonItem) => reasonItem.id === reasonCode
    );
    return itemFound.label;
  };

  const onCopyLatLng = (result) => {
    const text = result.Latitude + "," + result.Longitude;
    navigator.clipboard.writeText(text);
  };

  const onCopyData = (text) => {
    navigator.clipboard.writeText(text);
  };

  const onViewSimilarLocations = (e, items) => {
    setAnchorEl(e.currentTarget);
    setMoreItems(items);
  };

  const getTransactionById = (geoRefId) => {
    const result = points.find((item) => item.GeoReferenceId === geoRefId);
    const latLngGroup = points.filter(
      (item) =>
        item.Latitude === result.Latitude &&
        item.Longitude === result.Longitude &&
        item.GeoReferenceId !== result.GeoReferenceId
    );
  };

  return (
    <>
      {points.map((point) => {
        const location = {
          position: { lat: point.Latitude, lng: point.Longitude },
          key: point.GeoReferenceId,
          color: getColorByReasonCode(point.ReasonForFailure),
        };
        return (
          <ACTMarkers
            key={point.GeoReferenceId}
            point={location}
            onClick={handleMarkerClick}
            setMarkerRef={setMarkerRef}
          />
        );
      })}

      {selectedPinKey && (
        <InfoWindow
        anchor={markers[selectedPinKey]}
          onCloseClick={handleInfoWindowClose}
        >
          {selectedPin?.GeoReferenceId && (
            <ACTTransaction
              selectedGeoRefId={selectedPin?.GeoReferenceId}
              points={points}
              failedReasons={reasonsForFail}
              onCopyData={onCopyData}
              onViewSimilarLocations={onViewSimilarLocations}
            />
          )}
        </InfoWindow>
      )}
    </>
  );
};
