import React, { useState, useEffect, useContext } from "react";
import { styled, alpha, Box } from "@mui/system";
import MenuItem from "@mui/material/MenuItem";
import { Button, Menu, FormControlLabel, Checkbox } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { LOOKUP_MENUS } from "../../mock/menumock";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import { AppContext } from "../../context/AppContext";

// Define a custom MenuItem component
const CustomMenuItem = styled(MenuItem)(({ theme, isSelected }) => ({
  position: "relative",
  backgroundColor: "transparent", // Default background
  // backgroundColor: isSelected ? theme.palette.grey[300] : 'transparent',
  "&:hover": {
    backgroundColor: theme.palette.mode === "light" && theme.palette.grey[200],
  },
  "&:focus": {
    backgroundColor: theme.palette.mode === "light" && theme.palette.grey[300],
    "&:hover": {
      backgroundColor:
        theme.palette.mode === "light" && theme.palette.grey[200],
    },
  },
  "&:focusVisible, &:focusWithin, &:active": {
    backgroundColor: theme.palette.mode === "light" && theme.palette.grey[200], // Custom color when clicked
  },
  // Ensures no default styling is applied during click
  "&.Mui-focusVisible, &.Mui-selected, &.Mui-focusVisible.Mui-selected": {
    backgroundColor: theme.palette.mode === "light" && theme.palette.grey[300],
    outline: "none",
    boxShadow: "none",
  },

  // Disable transition effects that might cause delay
  transition: "background-color 0s",
  // Add a divider-like border after the last child using a pseudo-element
  "&::after": {
    content: '""',
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    height: "1px",

    backgroundColor: theme.palette.divider,
  },
}));

const StyledMenu = styled((props) => {
  const { selectedItems } = props;
  return (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  );
})(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    width: 260,

    color: "#000000",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "0.7rem 0",
    },
    "& .MuiMenuItem-root": {
      borderBottom: "0.08rem solid #d1d1d1",
      "& .MuiSvgIcon-root": {
        fontSize: 20,
        color: theme.palette.text.secondary,
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
    ...theme.applyStyles("dark", {
      color: theme.palette.grey[300],
    }),
  },
}));

const ACTDropdownWithCollapseMenuItemList = (props) => {
  const { options, title, onRefreshData, type, selectedItem, name } = props;
  const theme = useTheme();
  const appContext = useContext(AppContext);
  const { dashboardStates } = appContext;
  var found = LOOKUP_MENUS.find((item) => item.code === selectedItem?.Code);
  if (!found && selectedItem) {
    found = {
      title: selectedItem.FriendlyName,
      code: selectedItem.Code,
      items: [],
      selected: true,
    };
  }
  const [menuItems, setMenuItems] = useState(LOOKUP_MENUS);
  const labelName = dashboardStates["failed_reasons"];
  const [subMenuSelectedItems, setSubMenuSelectedItems] = useState(null);
  const [showFullList, setShowFullList] = useState(false); // To toggle dropdown
  const [selectedItems, setSelectedItems] = useState(
    found ? found : LOOKUP_MENUS[0]
  );

  // const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const [selectedInnerMenu, setSelectedInnerMenu] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRootMenuClose = (menuItem) => {
    if (onRefreshData) {
      const selectedItem = options.find((item) => item.id === menuItem.code);
      onRefreshData(selectedItem, type, name);
    }
    setAnchorEl(null);
  };

  const handleMenuClick = (item) => {
    const isAllChecksOrApproved = item.code === -2 || item.code === 0;
    const resetChildrenSelection = (children) => {
      return children.map((child) => ({
        ...child,
        selected: false,
        items: child.items ? resetChildrenSelection(child.items) : [], // Recursively reset child items
      }));
    };
    const menulist = menuItems.map((menu) => {
      if (menu.code === item.code) {
        return { ...menu, selected: !item?.selected };
      } else if (isAllChecksOrApproved) {
        // Reset "Failed Checks" and their children
        return {
          ...menu,
          selected: false,
          items: menu.items ? resetChildrenSelection(menu.items) : [], // Reset children selection
        };
      } else {
        return { ...menu, selected: false };
      }
    });
    setMenuItems(menulist);

    if (
      item.code === 101 ||
      item.code === 102 ||
      item.code === 103 ||
      item.code === 104 ||
      item.code === 105 ||
      item.code === -1
    ) {
    } else {
      if (onRefreshData) {
        const selectedItem = options.find(
          (menuitem) => menuitem.id === item.code
        );
        onRefreshData(selectedItem, type, name);
      }
      setSelectedItems(item);
    }
    updateSelectionState(menulist, '', '');
  };

  // Toggle dropdown visibility
  const toggleDropdown = () => setShowFullList(!showFullList);

  // Close dropdown
  const closeDropdown = () => setShowFullList(false);

  const handleSubMenuClick = (item) => {
    if (item.title === subMenuSelectedItems?.title) {
      const submenu = { ...item, selected: !subMenuSelectedItems?.selected };
      setSubMenuSelectedItems(submenu);
    } else {
      const submenu = { ...item, selected: true };
      setSubMenuSelectedItems(submenu);
    }

    if (
      item.code === 101 ||
      item.code === 102 ||
      item.code === 103 ||
      item.code === 104 ||
      item.code === 105 ||
      item.code === -1
    ) {
      return;
    }

    if (onRefreshData) {
      const selectedItem = options.find(
        (menuitem) => menuitem.id === item.code
      );
      onRefreshData(selectedItem, type, name);
    }
    setSelectedItems(item);
  };

  const handleInnerMenuClick = (menuItem) => {
    if (onRefreshData) {
      const selectedItem = options.find((item) => item.id === menuItem.code);
      onRefreshData(selectedItem, type, name);
    }
    setSelectedItems(menuItem);
    setAnchorEl(null);
    setSelectedInnerMenu(menuItem);
  };

  // Helper function to recursively update the selection state
  const updateSelectionState = (items, targetCode, selected) => {
    let targetCodeToStr = targetCode?.toString();
    // if target code -1 and selected true make all checkboxes checked

    return items.map((item) => {
      if (targetCodeToStr === '-1') {
        // Mark all items and their children as selected
        return {
          ...item,
          selected: selected,
          items: item.items ? updateSelectionState(item.items, targetCode, selected) : [],
        }
      }
      else if (item.code === targetCode) {
        // Update current item's selected state and all children
        return {
          ...item,
          selected: selected,
          items: item.items ? updateSelectionState(item.items, null, selected) : [],
        };
      } else if (item.items) {
        // Update children recursively
        return {
          ...item,
          items: updateSelectionState(item.items, targetCode, selected),
        };
      }
      return item;
    });
  };

  // Helper function to collect selected item IDs
  const getSelectedItemIds = (items) => {
    let selectedIds = [];
    items.forEach((item) => {
      if (item.selected) {
        selectedIds.push(item.code); // Collect the code of the selected item
      }
      if (item.items && item.items.length > 0) {
        selectedIds = selectedIds.concat(getSelectedItemIds(item.items)); // Recursively collect from child items
      }
    });
    return selectedIds;
  };

  // Handle parent or child selection
  const handleSelectionChange = (code, checked) => {
    // in inital load if default values getting unchecked, nothing selected in failed
    const SELECT_ALL_FAILED_CHECKS = '-1';
    const SELECT_ALL_CHECKS = '-2';
    const APPROVED_CHECKS = '0';
    const DEFAULT_IDS = '0, 3'
    let selectedItem;
    let selectedIdsString
    let codeStr = code.toString();

    const updatedItems = updateSelectionState(menuItems, code, checked);
    setMenuItems(updatedItems);

    // Update the failedChecksTitles state
    const updatedFailedChecksTitles = collectFailedChecksTitles(updatedItems);
    setFailedChecksTitles(updatedFailedChecksTitles);

    if (onRefreshData) {
      // Approved checks must be the default one if none of the items selected
      let dynamicCode = (codeStr && !checked) ? DEFAULT_IDS : codeStr;
      selectedItem = { id: dynamicCode }
      setSelectedItems(selectedItem);
      // Collect all selected item IDs
      if (codeStr !== SELECT_ALL_FAILED_CHECKS) {
        let selectedIds = getSelectedItemIds(updatedItems);
        // Remove -1 (Select All Failed Checks) and -2 (Select All) from the list of selected IDs
        selectedIds = selectedIds.filter(
          (id) =>
            id.toString() !== SELECT_ALL_FAILED_CHECKS &&
            id.toString() !== SELECT_ALL_CHECKS &&
            id.toString() !== APPROVED_CHECKS &&
            id.toString() !== '101' &&
            id.toString() !== '103' &&
            id.toString() !== '104' &&
            id.toString() !== '105'
        );

        // If unchecked, remove the corresponding `code`
        if (!checked) {
          selectedIds = selectedIds.filter((id) => id.toString() !== codeStr);
        }
        // Convert selected IDs to a comma-separated string
        selectedIdsString = selectedIds.join(',');

        // Pass the updated ID string directly to `onRefreshData` without modifying `options`
        selectedItem = { id: selectedIdsString }
      }
      onRefreshData(selectedItem, type, name);
    }
  };

  let provideTitle = labelName ? labelName.id === 0 ? "Approved Checks" : labelName.id === -2
    ? "All Checks"
    : "Failed Checks"
    : "Approved Checks";

  // Add this to the state definitions
  const [failedChecksTitles, setFailedChecksTitles] = useState(["Outside Location"]);

  // Use Effect to update titles dynamically when `items` change
  useEffect(() => {
    const selectedTitles = collectFailedChecksTitles(menuItems);

    // If there are no selected menuItems, retain the default "Outside Location"
    if (selectedTitles.length === 0) {
      setFailedChecksTitles([]);
    } else {
      setFailedChecksTitles(selectedTitles);
    }
  }, [menuItems]);

  // Helper function to collect failed checks titles recursively
  const collectFailedChecksTitles = (items) => {
    let titles = [];

    items.forEach((item) => {
      // Exclude specific titles: "All Checks", "Approved Checks", and "Failed Checks"
      if (item.selected && !["All Checks", "Approved Checks", "Failed Checks", "Spoofing Technology", "Modified Device", "Block Status", "Others"].includes(item.title)) {
        titles.push(item.title); // Add the title of the current item
      }

      // Recursively collect titles from nested items
      if (item.items && item.items.length > 0) {
        titles = titles.concat(collectFailedChecksTitles(item.items));
      }
    });

    return titles;
  };

  useEffect(() => {
    const storedIds = dashboardStates["failed_reasons"];

    if (storedIds) {
      let selectedIds = "";
      if (typeof storedIds === "string") {
        selectedIds = storedIds; // Already in the required format
      } else if (typeof storedIds === "object" && storedIds.id) {
        selectedIds = storedIds.id; // Extract the value directly
      }

      // Ensure selectedIds is a string before using `.includes`
      if (typeof selectedIds === "string" && selectedIds.includes("-1")) {
        // If `-1` is present, select all failed checks
        const updatedMenuItems = selectAllFailedChecks(menuItems);
        setMenuItems(updatedMenuItems);

        // Persist the updated menu items to localStorage
        localStorage.setItem("menuItemsState", JSON.stringify(updatedMenuItems));
      }
      else {
        // Otherwise, update based on the selected IDs
        const updatedMenuItems = updateMenuItemsWithLocalStorage(menuItems, selectedIds);
        setMenuItems(updatedMenuItems);
      }
    }
  }, []);

  const selectAllFailedChecks = (menuItems) => {
    return menuItems.map((item) => {
      // Recursively set `selected: true` for all "failed checks"
      const updatedItems = item.items?.length ? selectAllFailedChecks(item.items) : [];

      return {
        ...item,
        selected: true, // Mark the item as selected
        items: updatedItems,
      };
    });
  };

  const updateMenuItemsWithLocalStorage = (menuItems, selectedIds) => {
    const selectedIdArray = selectedIds.split(",").map(Number); // Convert string to an array of numbers
    return menuItems.map((item) => {
      const isSelected = selectedIdArray.includes(item.code);

      // If the item has nested items, recursively update them
      const updatedItems = item.items?.length
        ? updateMenuItemsWithLocalStorage(item.items, selectedIds)
        : [];

      return {
        ...item,
        selected: isSelected,
        items: updatedItems,
      };
    });
  };

  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <>
      <div>
        <Button
          id="demo-customized-button"
          aria-controls={open ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="text"
          disableElevation
          disableRipple
          onClick={handleClick}
          sx={{
            margin: "1rem",
            width: "250px",
            display: "flex",
            justifyContent: "space-between",
            fontWeight: "400",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: theme.palette.text.primary,
            "&:hover": {
              backgroundColor: "transparent",
            },
            boxShadow: "#d1d1d1",
          }}
          endIcon={
            anchorEl ? (
              <ArrowDropUpIcon
                sx={{
                  color: "#666666",
                  height: "1.5rem",
                }}
              />
            ) : (
              <ArrowDropDownIcon
                sx={{
                  color: "#666666",
                  height: "1.5rem",
                }}
              />
            )
          }
        >
          {provideTitle ? provideTitle : "Select Checks"}
          {/* If its not All checks and Approved checks keep the label as Failed checks */}
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {menuItems.flatMap((item, index) => {
            const isSelected = item.code === selectedItems?.code;
            if (item.items && item.items.length === 1) {
              return (
                <MenuItem
                  key={index}
                  onClick={handleClose}
                  disableRipple
                  sx={{
                    backgroundColor: isSelected && theme.palette.grey[300],
                    "&:hover": {
                      backgroundColor: theme.palette.grey[200],
                    },
                  }}
                >
                  {item.title}
                </MenuItem>
              );
            } else {
              return [
                <MenuItem
                  style={{ display: "flex", justifyContent: "space-between" }}
                  key={`${index}-main`}
                  onClick={() => {
                    handleMenuClick(item);
                    if (item.items && !item.items.length) handleClose();
                  }}
                  disableRipple
                  sx={{
                    backgroundColor: isSelected && theme.palette.grey[300],
                    "&:hover": {
                      backgroundColor: theme.palette.grey[200],
                    },
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {item.title}
                  {item.items && item.items.length > 0 ? (
                    item?.selected ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )
                  ) : null}
                </MenuItem>,
                item.selected &&
                item.items && item.items.map((subMenu, subindex) => {
                  const isSubMenuSelected = subMenu.code === subMenuSelectedItems?.code;
                  if (subMenu.items && subMenu.items.length === 1) {
                    return (
                      <MenuItem
                        style={{ marginLeft: "1rem" }}
                        key={`${index}-${subindex}`}
                        onClick={() => {
                          handleSubMenuClick(subMenu);
                          handleClose();
                        }}
                        disableRipple
                        sx={{
                          marginLeft: "1rem",
                          backgroundColor:
                            isSubMenuSelected && theme.palette.grey[300],
                          "&:hover": {
                            backgroundColor: theme.palette.grey[200],
                          },
                        }}
                      >
                        {subMenu.title}
                      </MenuItem>
                    );
                  } else {
                    return [
                      <MenuItem
                        style={{
                          paddingLeft: "1.2rem",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        key={`${index}-${subindex}-sub`}
                        onClick={() => {
                          handleSubMenuClick(subMenu);
                          if (!subMenu.items?.length) handleClose();
                        }}
                        disableRipple
                        sx={{
                          backgroundColor:
                            isSubMenuSelected && theme.palette.grey[300],
                          "&:hover": {
                            backgroundColor: theme.palette.grey[200],
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            backgroundColor:
                              isSubMenuSelected && theme.palette.grey[300],
                            "&:hover": {
                              backgroundColor: theme.palette.grey[200],
                            },
                          }}
                        >
                          {subMenu.items && subMenu.items.length === 0 && (
                            <Checkbox
                              checked={subMenu.selected}
                              onClick={(e) => e.stopPropagation()}
                              onChange={(e) =>
                                handleSelectionChange(subMenu.code, e.target.checked)
                              }
                            />
                          )}
                          {subMenu.title}
                        </Box>
                        {subMenu.items?.length > 0 ? (
                          subMenuSelectedItems?.title === subMenu.title &&
                            subMenuSelectedItems?.selected ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )
                        ) : null}
                      </MenuItem>,
                      subMenuSelectedItems?.title === subMenu.title &&
                      subMenuSelectedItems?.selected &&
                      subMenu.items?.map((innerMenu, innerindex) => (
                        <MenuItem
                          key={`${index}-${subindex}-${innerindex}-inner`}
                          style={{
                            paddingLeft: "2rem",
                            backgroundColor:
                              selectedInnerMenu?.code === innerMenu.code &&
                              theme.palette.grey[300],
                          }}
                          onClick={() => handleInnerMenuClick(innerMenu)}
                          disableRipple
                        >
                          <Checkbox
                            onClick={(e) => e.stopPropagation()}
                            checked={innerMenu.selected}
                            onChange={(e) => handleSelectionChange(innerMenu.code, e.target.checked)}
                          />
                          {innerMenu.title}
                        </MenuItem>
                      )),
                    ];
                  }
                }),
              ];
            }
          })}
        </StyledMenu>
      </div>
      {/* Input-like Box */}
      {failedChecksTitles.length >= 1 && <div style={{ position: 'relative', width: '250px', marginRight: '30px' }}>
        <div
          style={{
            border: '1px solid #ccc',
            borderRadius: '5px',
            padding: '10px',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            maxHeight: '50px', // Add a fixed height here to prevent resizing
            overflow: 'hidden',
          }}
          onClick={toggleDropdown}
        >
          {failedChecksTitles.length > 0 ? (
            <span>
              {failedChecksTitles.slice(0, 2).join(', ')}
              {failedChecksTitles.length > 2 ? '...' : ''}
            </span>
          ) : (
            <span>No items selected</span>
          )}
          <div onClick={handleToggle}>
            {isExpanded ? (
              <ArrowDropUpIcon />
            ) : (
              <ArrowDropDownIcon />
            )}
          </div>
        </div>

        {/* Dropdown for Full List */}
        {showFullList && (
          <div
            style={{
              position: 'absolute',
              top: '100%',
              left: 0,
              width: '100%',
              border: '1px solid #ccc',
              borderRadius: '5px',
              backgroundColor: '#fff',
              zIndex: 1000,
              maxHeight: '150px',
              overflowY: 'auto',
              backgroundColor: theme.palette.mode === "dark" ? "#333" : "#fff", // Adjust background for dark mode
              color: theme.palette.text.primary,
            }}
          >
            {failedChecksTitles?.map((itemName, index) => (
              <div
                key={index}
                style={{
                  padding: '10px',
                  borderBottom: '1px solid #f0f0f0',
                  cursor: 'default',
                  color: theme.palette.text.primary,
                  borderBottom: theme.palette.mode === "dark" ? "1px solid #555" : "1px solid #f0f0f0", // Adjust border color for dark mode
                }}
              >
                {itemName}
              </div>
            ))}
            {failedChecksTitles.length === 0 && <div style={{ padding: '10px', color: theme.palette.text.secondary, }}>No items selected</div>}
          </div>
        )}
      </div>}
    </>
  );
};

export default ACTDropdownWithCollapseMenuItemList;
