import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import { IconButton } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";
import BlockIcon from '@mui/icons-material/Block';
import { useTheme } from '@mui/material/styles';

const SummaryToolbar = (props) => {
  const { count, exportHandler, printHandler, summaryType, blockHandler } = props;
  const theme = useTheme();
  
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          fontSize: "1rem",
          fontWeight: "500",
          flexGrow: 1,
          color: theme.palette.text.primary
        }}
      >
        {summaryType === "device"
          ? "Account used (" + count + ")"
          : "Devices (" + count + ")"}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          marginRight: "1rem",
          gap: "1rem",
        }}
      >
        <IconButton onClick={blockHandler}>
          <BlockIcon />
        </IconButton>
        {/* <IconButton onClick={printHandler} disabled>
          <PrintIcon />
        </IconButton>
        <IconButton onClick={exportHandler}>
          <DownloadIcon />
        </IconButton> */}
      </Box>
    </Box>
  );
};

export default SummaryToolbar;
