import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";

const GlossaryButton = styled(Button)(({ theme, btnState }) => ({
    color: btnState ? "#000000" : theme.palette.getContrastText(grey[500]),
    fontSize: "1.4rem",
    fontWeight: "900",
    height: "2rem",
    width: "2rem",
    minWidth: "2rem",
    border: btnState ? "0.1rem #000000 solid" : "0.1rem #000000 solid",
    borderRadius: "50%",
    backgroundColor: btnState ? "#ffffff" : "none",
    "&:hover": {
      backgroundColor: "#ffffff",
      border: "0.1rem #999999 solid",
      color: "#000000",
    },
    "&:active": {
      backgroundColor: "#ffffff",
      border: "0.1rem #999999 solid",
      color: "#000000",
    }
  }));

  export default GlossaryButton;