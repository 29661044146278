import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { getCurrentDateYYYYMMDDWithTime } from "../../utils/DateUtils";
import CommonListView from "../commonListView/CommonListView";

const ACTCardDataGridTable = (props) => {
  const { data, columns, summaryType, summaryId, tabIndex, toolbar, onRowSelection } = props;

  const getFileName = () => {
    const strDateTime = getCurrentDateYYYYMMDDWithTime();
    return summaryId + "_" + summaryType + "_" + strDateTime + ".csv";
  };
  const CustomToolbar = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          margin: "10px",
        }}
      >
        <GridToolbarContainer>
          <GridToolbarExport csvOptions={{ fileName: getFileName() }} />
        </GridToolbarContainer>
      </Box>
    );
  };
  return (
    <Card>
      <CardHeader title={toolbar} />
      <CardContent>
        {data && (
          <CommonListView
            rows={data}
            columns={columns}
            pageSize={10}
            getRowId={(row) =>
              summaryType === "device" ? row.AccountId : row.DeviceId
            }
            height={tabIndex === 1 ? "70vh" : "50vh"}
            toolbar={null}
            paginationMode={null}
            onRowSelection={onRowSelection}
            data={data}
            summaryType={summaryType}
            summaryId={summaryId}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default ACTCardDataGridTable;
