export const getRandomHexColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getColorByDeviceType = (deviceType) => {
  if (deviceType.toLowerCase() === "ios") {
    return "#E040FB";
  } else if (deviceType.toLowerCase() === "android") {
    return "#2196F3";
  } else if (deviceType.toLowerCase() === "undetermined") {
    return "#FFC107";
  }
};
