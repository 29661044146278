import { useContext } from "react";
import { Main } from "../components/shared/Main";
import { AppContext } from "../context/AppContext";
import { DrawerHeader } from "../components/sidebar/DrawerHeader";
import SeacrhListView from "../components/search/SearchListView";

const Search = () => {
  const appContext = useContext(AppContext);

  return (
    <Main
      open={appContext.drawerOpen}
      sx={{
        overflowX: "hidden",
        height: '100%',
        minHeight: '100vh',
      }}
    >
      <DrawerHeader />
      <SeacrhListView />
    </Main>
  );
};

export default Search;
