import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@emotion/react";

export default function ACTFormDialog(props) {
  const { openDialog, title, closeHandler, submitHanlder } = props;

  const handleClose = () => {
    closeHandler(false);
  };
  const theme = useTheme();

  return (
    <React.Fragment>
      <Dialog
        sx={{
          "& .MuiPaper-root": {
            width: "90vw",
            maxWidth: "23.438rem",
          },
        }}
        open={openDialog}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const notes = formJson.notes;
            submitHanlder(notes);
            handleClose();
          },
        }}
      >
        <CloseIcon
          onClick={handleClose}
          sx={{
            height: "1.2rem",
            position: "absolute",
            right: "1rem",
            top: "1rem",
            cursor: "pointer",
          }}
        />
        <DialogTitle
          sx={{
            padding: "2.5rem 2rem 1rem 2.5rem",
            fontSize: "1rem",
            fontWeight: "700",
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent
          sx={{
            padding: "2rem 1.5rem 0 1.5rem",
          }}
        >
          <DialogContentText
            sx={{
              fontSize: "0.8rem",
              fontWeight: "500",
              padding: "0 0 0 1rem",
            }}
          >
            Please enter reason
          </DialogContentText>
          <TextField
            sx={{
              paddingLeft: { sm: 0, md: 2 },
              paddingRight: { sm: 0, md: 2 },
              margin: "2rem 0 0 0",
              width: "100%",
              color: theme.palette.text.primary,
              "& .MuiInputBase-root": {
                fontSize: 14,
              },
            }}
            id="note"
            name="notes"
            autoFocus
            multiline
            required
            fullWidth
            inputProps={{ maxLength: 100 }}
            rows={4}
          />
          <Typography
            sx={{
              fontSize: "0.6rem",
              fontWeight: "400",
              paddingTop: "0.5rem",
              paddingLeft: { sm: 0, md: 2 },
              paddingRight: { sm: 0, md: 2 },
              color: theme.palette.text.primary,
            }}
          >
            Max 100 characters
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            padding: "1rem 2rem 2.25rem 2rem",
          }}
        >
          <Button
            sx={{
              fontSize: "0.7rem",
              fontWeight: "600",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            sx={{
              fontSize: "0.7rem",
              fontWeight: "600",
            }}
            type="submit"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
