import { axiosApiInstance } from "../network/axiosmanager";
import HttpException from "../common/exceptions/HttpException";

import {
    BASE_URL,
    REPORT_S1T,
    REPORT_S2T
} from "../constants/APIConstants";

export const fetchDashboardSearchData = async (payload) => {
    const response = await axiosApiInstance
        .post(BASE_URL + REPORT_S1T, payload)
        .catch(HttpException.create);
    return response.data;
};

export const fetchDashboardAdvancedSearchData = async (payload) => {
    const response = await axiosApiInstance
    .post(BASE_URL + REPORT_S2T, payload)
    .catch(HttpException.create);
return response.data;
}
