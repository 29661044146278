import React, { useState, useContext } from "react";
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarFilterButton,
} from "@mui/x-data-grid";
import "./CommonListViewStyles.scss"; // Optional: Only include if custom styles are needed
import { Box } from "@mui/material";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { MenuItem } from '@mui/material';
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import { IconButton, Menu } from "@mui/material";
import { useGridApiContext } from '@mui/x-data-grid'
import { AppContext } from "../../context/AppContext";

const CustomExportButton = (props) => {
    const { handleCsvExport1, summaryData, summaryType, summaryId } = props;
    // Make sure you call hooks inside this function component
    const apiRef = useGridApiContext();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={handleMenuOpen}>
                <DownloadIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={() => handleCsvExport1(summaryData, summaryType, summaryId)}>Export as CSV</MenuItem>
                {/* <MenuItem onClick={handleExcelExport}>Export as Excel</MenuItem> */}
            </Menu>
        </>

    );
}


const CustomToolbarButton = (props) => {
    const appContext = useContext(AppContext);
    const { onPrint, summaryData, summaryType, summaryId, handleCsvExport1 } = props;

    // Create a custom theme
    const customTheme = createTheme({
        components: {
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        color: 'blue', // Set the default color for buttons
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        // Custom styles for all buttons
                        color: appContext.darkMode ? '#ffffff' : 'light',
                    },
                },
            },
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        color: 'grey', // Set icon color to blue
                    },
                },
            },
        },
        palette: {
            primary: {
                main: '#000000',
            },
            secondary: {
                main: '#FFFFFF',
                light: '#FFFFFF',
                // dark: will be calculated from palette.secondary.main,
                contrastText: '#47008F',
            },
        },
    });

    return (
        <ThemeProvider theme={customTheme}>
            <GridToolbarContainer >
                <GridToolbarColumnsButton className="custom-toolbar-button custom-toolbar-icon" />
                <GridToolbarFilterButton className="custom-toolbar-button custom-toolbar-icon"
                    PopperProps={{
                        modifiers: [
                            {
                                name: "offset",
                                options: {
                                    offset: [0, 8], // Controls the spacing between the button and the dropdown
                                },
                            },
                            {
                                name: "preventOverflow",
                                options: {
                                    boundary: "window",
                                },
                            },
                        ],
                    }}
                    sx={{
                        '& .MuiDataGrid-filterForm': {
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px', // Adjusts the gap between filters for proper spacing
                            padding: '10px',
                        },
                        '& .MuiFormControl-root': {
                            minWidth: '150px', // Ensures proper minimum width for inputs
                        },
                        '& .MuiInputBase-root': {
                            minWidth: '120px', // Ensures filter fields have the correct width
                        },
                        '& .MuiSelect-root': {
                            minWidth: '120px',
                        },
                    }}
                />
                <Box sx={{ flexGrow: 1 }} />
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <IconButton onClick={onPrint}>
                        <PrintIcon />
                    </IconButton>
                    <CustomExportButton
                        handleCsvExport1={handleCsvExport1}
                        summaryData={summaryData}
                        summaryType={summaryType}
                        summaryId={summaryId}
                    />
                </Box>
            </GridToolbarContainer>
        </ThemeProvider>
    );
}

export default CustomToolbarButton;