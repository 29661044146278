import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/material";

const SummaryTabHeader = (props) => {
  const { onTabSelect, onRefreshData, type, count, currentTab } = props;
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(currentTab);
  }, [currentTab]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (onTabSelect) {
      onTabSelect(newValue);
    }
  };

  return (
    <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <Tabs value={value} onChange={handleChange} centered>
        <Tab
          label="SUMMARY"
          sx={{
            fontWeight: value === 0 ? "bold" : "medium",
            color: value === 0 ? "black" : "gray",
          }}
        />
        <Tab
          label={"CHECKS (" + count + ")"}
          sx={{
            fontWeight: value === 1 ? "bold" : "medium",
            color: value === 1 ? "black" : "gray",
          }}
        />
        <Tab
          label="LOCATIONS"
          sx={{
            fontWeight: value === 2 ? "bold" : "medium",
            color: value === 2 ? "black" : "gray",
          }}
        />
      </Tabs>
    </Box>
  );
};

export default SummaryTabHeader;
