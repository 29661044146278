import React, { useContext, useEffect, useState } from "react";
import CommonListView from "../commonListView/CommonListView";
import { useLocation } from "react-router-dom";
import { IconButton, Box, Typography, TextField } from "@mui/material";
import {
  fetchDashboardAdvancedSearchData,
  fetchDashboardSearchData,
} from "../../services/SearchService";
import { useNavigate } from "react-router-dom";
import SearchResultToolbar from "./SearchResultToolbar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { AppContext } from "../../context/AppContext";
import { getSearchDataGridColumns } from "./SearchDataGridColumns";
import Loader from "../loader/Loader";
import GenericError from "../GenericError/GenericError";
import {
  formatDateBasedOnLocale,
  getCurrentDateYYYYMMDD,
  getCurrentDateYYYYMMDDWithTime,
} from "../../utils/DateUtils";
import {
  exportToCsv,
  getLocatedNameByCode,
  getReasonsNameByCode,
  getValidationStatus,
} from "../../utils/CommonUtils";
import { ToastContainer, toast } from "material-react-toastify";
import {
  showCatchedExceptionOnToast,
  validateAnsShowServerException,
} from "../../utils/ExceptionUtils";
import LocationImage from "../../assets/images/search-location.png";
import IpImage from "../../assets/images/search-ip.png";
import TransactionImage from "../../assets/images/search-transactions.png";
import ResultsImage from "../../assets/images/search-results.png";
import { useTheme } from "@emotion/react";
import { SEARCH_CRITERIA_LIST } from "../../constants/Constants";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ACTDeleteSavedReportDialog from "../shared/ACTDeleteSavedReportDialog";
import MapDialog from "../home/maps/dialogmap/MapDialog";

const noDataReasons = [
  {
    title:
      "We couldn't find any transactions that match your criteria. Please make some edits and try again.",
    image: TransactionImage,
  },
  {
    title:
      "We couldn't find any locations that match your criteria. Please make some edits and try again.",
    image: LocationImage,
  },
  {
    title:
      "We couldn't find any IP Addresses that match your criteria. Please make some edits and try again.",
    image: IpImage,
  },
  {
    title:
      "We couldn't find any search results that match your criteria. Please make some edits and try again.",
    image: ResultsImage,
  },
];

const SearchListView = (props) => {
  const { selectedSearchItem } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const { selectedUserApp } = appContext;
  const { searchParam, advanceSearch, type } = location.state || {};
  const theme = useTheme();
  const [showLoader, setShowLoader] = useState(false);
  const [searchListData, setSearchListData] = useState(null);
  const [requestParam, setRequestParam] = useState(null);
  const [showError, setShowError] = useState(false);
  const [showEditInput, setShowEditInput] = useState(true);
  const [title, setTitle] = useState("");
  const [items, setItems] = useState([]);
  const [savedSearchItem, setSavedSearchItem] = useState(null);
  const [showDeleteSavedReportDialog, setShowDeleteSavedReportDialog] =
    useState(false);

  const [selectedLocation, setSelectedLocation] = useState(null);

  const [dateFilter, setDateFilter] = useState({
    startDate: getCurrentDateYYYYMMDD(),
    endDate: getCurrentDateYYYYMMDD(),
  });

  useEffect(() => {
    if (advanceSearch) {
      getAdvanceSearchResults(searchParam);
    } else {
      getSearchResults(searchParam);
    }
  }, [searchParam]);

  useEffect(() => {
    if (!requestParam) return;
    if (advanceSearch) {
      getAdvanceSearchResults(requestParam);
    } else {
      getSearchResults(requestParam);
    }
  }, [requestParam]);

  const getErrorByType = () => {
    if (searchParam?.GeoReferenceId) {
      return noDataReasons[0];
    } else if (searchParam?.Latitude && searchParam?.Longitude) {
      return noDataReasons[1];
    } else if (searchParam?.IpAddress) {
      return noDataReasons[2];
    } else {
      return noDataReasons[3];
    }
  };

  const getSearchResults = (payload) => {
    setShowLoader(true);
    fetchDashboardSearchData(payload)
      .then((response) => {
        if (response.Status === 0) {
          if (
            type &&
            type === "AccountId" &&
            response.Data.Results.length === 1
          ) {
            const data = response.Data.Results[0];
            navigate("/summary/account/" + data.AccountID, {
              state: { type: "account", id: data.AccountID },
            });
          } else {
            setSearchListData(response);
            setShowError(false);
          }
        } else {
          validateAnsShowServerException(toast, 2, response.Status, appContext);
          setSearchListData(null);
          setShowError(true);
        }
      })
      .catch((err) => {
        setShowError(true);
        setSearchListData(null);
        showCatchedExceptionOnToast(toast, 2, err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const getAdvanceSearchResults = (payload) => {
    setShowLoader(true);
    const updatedPayload = { ...payload, JWTid: appContext.userDetails.JWTid };
    fetchDashboardAdvancedSearchData(payload)
      .then((response) => {
        if (response.Status === 0) {
          setSearchListData(response);
          setShowError(false);
        } else {
          validateAnsShowServerException(toast, 2, response.Status, appContext);
          setShowError(true);
          setSearchListData(null);
        }
      })
      .catch((err) => {
        setShowError(true);
        setSearchListData(null);
        showCatchedExceptionOnToast(toast, 2, err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  // const onExportData = () => {
  //   const strDateTime = getCurrentDateYYYYMMDDWithTime();
  //   const filename = "search_" + strDateTime + ".csv";
  //   const data = searchListData?.Data.Results.map((item) => {
  //     return {
  //       "Customer (AccountId)": item.AccountID,
  //       "Device ID": item.DeviceID,
  //       "Transaaction ID": item.GeoReferenceId,
  //       "Transaaction Date": formatDateBasedOnLocale(item.Timestamp),
  //       Status: getValidationStatus(item.Validated),
  //       "Reason For Failure": getReasonsNameByCode(
  //         item.ReasonForFailure,
  //         appContext.failedReasons
  //       ),
  //       Location: item.Latitude + ", " + item.Longitude,
  //       "Located By": getLocatedNameByCode(item.GeoLookUpType),
  //     };
  //   });
  //   exportToCsv(filename, data);
  // };
  // const onPrintData = () => {};
  const onClickBack = () => {
    navigate(-1);
  };

  const refreshDate = (data) => {
    const request = { ...searchParam, PageNumber: data.page };
    setRequestParam(request);
  };

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("items"));
    if (items) {
      setItems(items);
    }
    const searchCritriaTitle = localStorage.getItem(SEARCH_CRITERIA_LIST);
    if (searchCritriaTitle) {
      const list = JSON.parse(searchCritriaTitle);
      if (location.search?.length > 0) {
        const locationRef = location.search.substring(1);

        const savedSearchItem = list.find(
          (item) => String(item.key) === String(locationRef)
        );
        setSavedSearchItem(savedSearchItem);
        setDateFilter({
          startDate: savedSearchItem?.search.startDate,
          endDate: savedSearchItem?.search.endDate,
        });
        setTitle(savedSearchItem?.title);
      } else {
        setSavedSearchItem(null);
      }
    }
  }, [location, appContext.refreshSavedSearchSideMenu]);

  const openEditInput = () => {
    setShowEditInput(false);
  };

  const closeEditInput = () => {
    setTitle(savedSearchItem.title);
    setShowEditInput(true);
  };

  useEffect(() => {
    if (selectedSearchItem) {
      setTitle(selectedSearchItem.name);
    }
  }, [selectedSearchItem]);

  const onEditTitle = (title) => {
    setTitle(title);
  };

  const savedEditedTitle = () => {
    closeEditInput();

    const searchCriteriaList = localStorage.getItem(SEARCH_CRITERIA_LIST);
    if (searchCriteriaList) {
      const json = JSON.parse(searchCriteriaList);
      const saveSearchInLocalStorageList = json.map((item) => {
        if (item.title === savedSearchItem.title) {
          return { ...item, title: title };
        } else {
          return item;
        }
      });
      localStorage.setItem(
        SEARCH_CRITERIA_LIST,
        JSON.stringify(saveSearchInLocalStorageList)
      );
      appContext.setRefreshSavedSearchSideMenu(Math.random());
    }
  };

  const openDeleteSavedSearchDialog = (item) => {
    setShowDeleteSavedReportDialog(true);
  };

  const onLatLngClick = (params) => {
    setSelectedLocation(params);
  };

  const handleCloseMap = () => {
    setSelectedLocation(null);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        {savedSearchItem !== null && (
          <>
            <Typography
              sx={{
                fontSize: "1.3rem",
                fontWeight: "400",
                marginBottom: "0.5rem",
                color: theme.palette.text.primary,
                textWrap: "nowrap",
              }}
            >
              {selectedUserApp.AppName}
            </Typography>

            {showEditInput ? (
              <>
                <Typography
                  onClick={openEditInput}
                  sx={{
                    fontSize: "1.3rem",
                    fontWeight: "500",
                    marginBottom: "0.5rem",
                    color: theme.palette.text.primary,
                    "&:hover": {
                      opacity: "0.6",
                    },
                    cursor: "pointer",
                  }}
                >
                  {savedSearchItem?.title}
                </Typography>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                  }}
                >
                  <TextField
                    sx={{
                      color: theme.palette.text.primary,
                      "& .MuiInputBase-root": {
                        fontSize: 14,
                        padding: "0.25rem 0.5rem",
                        marginBottom: "0.4rem",
                        overflow: "hidden",
                      },
                    }}
                    className="textField"
                    type="text"
                    value={title}
                    onChange={(e) => onEditTitle(e.target.value)}
                    autoFocus
                    inputProps={{ maxLength: 15 }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      gap: "0.5rem",
                      alignItems: "center",
                      marginBottom: "0.25rem",
                    }}
                  >
                    <button className="edit-button" onClick={savedEditedTitle}>
                      <DoneIcon
                        sx={{
                          color: "#000000",
                          fontSize: "0.9rem",
                          cursor: "pointer",
                        }}
                      />
                    </button>
                    <button className="edit-button" onClick={closeEditInput}>
                      <CloseIcon
                        sx={{
                          color: "#000000",
                          fontSize: "0.9rem",
                          cursor: "pointer",
                        }}
                      />
                    </button>
                  </Box>
                </Box>

                <Box
                  sx={{
                    marginBottom: "0.25rem",
                  }}
                >
                  <button
                    className="delete-report-button"
                    onClick={() => openDeleteSavedSearchDialog()}
                  >
                    DELETE REPORT
                  </button>
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>

      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <IconButton onClick={onClickBack}>
          <ChevronLeftIcon style={{ color: theme.palette.text.primary }} />
        </IconButton>
        <SearchResultToolbar
          resultCount={
            searchListData && searchListData.Data
              ? searchListData.Data.TotalRecords
              : 0
          }
          // exportHandler={onExportData}
          // printHandler={onPrintData}
        />
      </Box>
      <Box sx={{ marginTop: "1rem" }}>
        {searchListData &&
          searchListData.Data &&
          searchListData.Data.Results.length > 0 && (
            <CommonListView
              rows={searchListData?.Data.Results}
              columns={getSearchDataGridColumns(
                appContext.failedReasons,
                theme,
                onLatLngClick
              )}
              rowCount={searchListData.Data.TotalRecords}
              toolbar={null}
              pageSize={100}
              onRefreshData={(paginationModel) => refreshDate(paginationModel)}
              getRowId={(row) => row.GeoReferenceId}
              checkboxSelection
              height={"80vh"}
            />
          )}
        {searchListData && searchListData.Data === null && (
          <GenericError data={getErrorByType()} />
        )}
        {searchListData &&
          searchListData.Data &&
          searchListData.Data.Results.length === 0 && (
            <GenericError data={getErrorByType()} />
          )}
        {!searchListData && showError && (
          <GenericError data={getErrorByType()} />
        )}
      </Box>
      {showLoader && <Loader />}
      <ToastContainer position="top-center" autoClose={3000} />

      {savedSearchItem && (
        <ACTDeleteSavedReportDialog
          openDialog={showDeleteSavedReportDialog}
          closeHandler={setShowDeleteSavedReportDialog}
          selectedSearchItem={savedSearchItem}
        />
      )}
      {selectedLocation && (
        <MapDialog
          params={selectedLocation}
          open={selectedLocation ? true : false}
          onClose={handleCloseMap}
        />
      )}
    </>
  );
};

export default SearchListView;
