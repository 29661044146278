import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { IconButton } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";
import DashboardDatepicker from "../shared/DashboardDatepicker";
import {
  getCurrentDateYYYYMMDD,
  getDateBySubtractMonth,
} from "../../utils/DateUtils";
import dayjs from "dayjs";
import { useTheme } from "@emotion/react";

const TransactionsToolbar = (props) => {
  const {
    summaryType,
    summaryId,
    // exportHandler,
    // printHandler,
    refreshDataHandler,
  } = props;
  const theme = useTheme();
  const [startDate, setStartDate] = useState(getDateBySubtractMonth(3));
  const [endDate, setEndDate] = useState(getCurrentDateYYYYMMDD());

  const onDateChange = (value, type) => {
    if (type === 1) {
      setStartDate(value);
      if(refreshDataHandler){
        refreshDataHandler({ startDate: value, endDate }, 1);
      }
    } else if (type === 2) {
      setEndDate(value);
      if(refreshDataHandler){
        refreshDataHandler({ startDate, endDate: value }, 1);
      }
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          flexGrow: 1,
        }}
      >
        <DashboardDatepicker
          onRefreshData={onDateChange}
          type={1}
          defaultDate={dayjs().subtract(3, "month")}
        />
        <DashboardDatepicker
          onRefreshData={onDateChange}
          type={2}
          defaultDate={dayjs()}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        {summaryType === "device" && (
          <div
            style={{
              display: "flex",
              fontSize: "1rem",
              fontWeight: "500",
              flexDirection: "row",
              marginRight: "1rem",
            }}
          >
            {"Device:"}
            <div
              style={{
                fontSize: "1rem",
                fontWeight: "400",
                marginLeft: "0.25rem",
              }}
            >
              {summaryId}
            </div>
          </div>
        )}
        {summaryType === "account" && (
          <div
            style={{
              display: "flex",
              fontSize: "1rem",
              fontWeight: "500",
              flexDirection: "row",
              marginRight: "1rem",
            }}
          >
            {"Account:"}
            <div
              style={{
                fontSize: "1rem",
                fontWeight: "400",
                marginLeft: "0.25rem",
              }}
            >
              {summaryId}
            </div>
          </div>
        )}

        {/* <IconButton onClick={printHandler} disabled>
          <PrintIcon />
        </IconButton>
        <IconButton onClick={exportHandler} sx={{ backgroundColor: theme.palette.mode === 'dark' ? '#1c1c1c' : '#d8d8d8',
      color: theme.palette.text.primary }}>
          <DownloadIcon />
        </IconButton> */}
      </Box>
    </Box>
  );
};

export default TransactionsToolbar;
