import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/material";

const DashboardTabHeader = (props) => {
  const { selectedTabItem, onTabSelect, onRefreshData, type } = props;

  const [value, setValue] = useState(selectedTabItem);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (onTabSelect) {
      onTabSelect(newValue);
    }
  };

  useEffect(() => {
    setValue(selectedTabItem);
  }, [selectedTabItem]);

  return (
    <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        // textColor="#000000"
        // TabIndicatorProps={{
        //   style: {
        //     backgroundColor: "#000000",
        //   },
        // }}
        centered
      >
        <Tab
          label="Map"
          sx={{
            fontWeight: value === 0 ? "bold" : "medium",
            color: value === 0 ? "black" : "gray",
          }}
        />
        <Tab
          label="Transactions"
          sx={{
            fontWeight: value === 1 ? "bold" : "medium",
            color: value === 1 ? "black" : "gray",
          }}
        />
      </Tabs>
    </Box>
  );
};

export default DashboardTabHeader;
