import { Box, IconButton, Typography } from "@mui/material";
import { CopyAllRounded } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import ACTTransactionsMultipleAllList from "./ACTTransactionsMultipleAllList";
import "./ACTTransactionsMultipleStyles.scss";

const ACTTransactionsMultiple = ({
  selectedGeoRefId,
  points,
  failedReasons,
  onCopyData,
}) => {
  const [showSimilarLocation, setShowSimilarLocation] = useState(false);
  const [showViewButton, setShowViewButton] = useState(true);
  const result = points.find(
    (item) => item.GeoReferenceId === selectedGeoRefId
  );
  const latLngGroup = points.filter(
    (item) =>
      item.Latitude === result.Latitude && item.Longitude === result.Longitude
  );
  const theme = useTheme();
  const customtextColorForDarkmode = (theme) => ({
    color: theme.palette.mode === "dark" && "#ffffff",
  });

  const toggleSetShowSimilarLocation = () => {
    if (showSimilarLocation === false) {
      setShowSimilarLocation(true);
      console.log("true location");
    } else if (showSimilarLocation === true) {
      setShowSimilarLocation(false);
      console.log("false location");
    }
  };

  const toggleShowViewButton = () => {
    if (showViewButton === false) {
      setShowViewButton(true);
      console.log("true button");
    } else if (showViewButton === true) {
      setShowViewButton(false);
      console.log("false button");
    }
  };

  const onViewSimilarLocations = () => {
    toggleShowViewButton();
    toggleSetShowSimilarLocation();
  };

  return (
    <Box
      sx={{
        width: "90vw",
        maxWidth: "31.5rem",
        maxHeight: "21rem",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",

      }}
      style={customtextColorForDarkmode(theme)}
    >
      <Typography
        style={customtextColorForDarkmode(theme)}
        sx={{
          padding: "0rem 0rem 0.5rem 1rem",
          fontSize: "1.25rem",
          fontWeight: "700",
        }}
      >
        There are {latLngGroup.length} checks in this location
      </Typography>

      <Box
        sx={{
          paddingRight: "1.2rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "auto",
        }}
      >
        <Typography
          style={customtextColorForDarkmode(theme)}
          sx={{
            fontSize: "0.8rem",
            fontWeight: "300",
            padding: "0 0 0.5rem 1rem",
          }}
        >
          <strong>Lat & Long:</strong> {result.Latitude}, {result.Longitude}
        </Typography>
        <Box>
          <IconButton
            aria-label="copy"
            size="small"
            onClick={() => onCopyData(result.Latitude + "," + result.Longitude)}
          >
            <CopyAllRounded
              sx={{
                fontSize: "1.3rem",
              }}
              style={customtextColorForDarkmode(theme)}
            />
          </IconButton>
        </Box>
      </Box>

      {showSimilarLocation && (
        <Box
          sx={{
            border: "0.1rem solid #ececec",
            maxHeight: "11rem",
            overflowY: "scroll",
            margin: "0.5rem 0 1rem 1rem",
            padding: "0rem",
          }}
        >
          <ACTTransactionsMultipleAllList
            failedReasons={failedReasons}
            onCopyData={onCopyData}
            moreItems={latLngGroup}
            latLngGroup={latLngGroup}
          />
        </Box>
      )}

      <Typography
        sx={{
          fontSize: "0.8rem",
          fontWeight: "400",
        }}
        className={
          showViewButton
            ? "show-locations-button-closed"
            : "show-locations-button-open"
        }
        style={{ cursor: "pointer", color: "#1e86f0" }}
        onClick={(e) => onViewSimilarLocations(e, latLngGroup)}
      >
        {showViewButton ? "view all checks" : "hide checks"}
      </Typography>
    </Box>
  );
};

export default ACTTransactionsMultiple;
