import { Box, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { AppContext } from "../../context/AppContext";
import { fetchDashboardReport } from "../../services/HomeService";
import { toast, ToastContainer } from "material-react-toastify";
import {
  isTokenError,
  showCatchedExceptionOnToast,
  showServerExceptionOnToast,
  validateAnsShowServerException,
} from "../../utils/ExceptionUtils";
import {
  REPORT_B1T,
  REPORT_B2T,
  REPORT_B3T,
  REPORT_D1T,
} from "../../constants/APIConstants";
import {
  DASHBOARD_D1T,
  DEFAULT_PAGE_SIZE,
  DEFAULT_TIMEZONE_OFFSET,
} from "../../constants/Constants";
import {
  getCurrentDateYYYYMMDD,
  getDayBySubtractingNumDays,
} from "../../utils/DateUtils";
import Loader from "../loader/Loader";
import CommonListView from "../commonListView/CommonListView";
import { getSavedReportDataGridColumns } from "./SavedReportDataGridColumns";
import { useTheme } from "@emotion/react";
import {
  fetchActivities,
  fetchDevices,
  fetchFailedReasons,
  fetchListReasonsForFail,
} from "../../services/HomeService";
import { BASE_B1T, BASE_B2T, BASE_B3T } from "../../constants/Constants";
import ResultsImage from "../../assets/images/search-results.png";
import GenericError from "../GenericError/GenericError";
import ACTPieCard from "../charts/ACTPieCard";
import {
  getColorByDeviceType,
  getRandomHexColor,
} from "../../utils/ColorUtils";
import { removeAuthInfo } from "../../utils/AuthUtils";
import MapDialog from "../home/maps/dialogmap/MapDialog";

const noDataReasons = {
  title: "Currently no saved report data.",
  image: ResultsImage,
};

const SavedReportContainer = () => {
  const appContext = useContext(AppContext);
  const location = useLocation();
  const { selectedUserApp, getUserReportByAPI, failedReasons } = appContext;
  const [showLoader, setShowLoader] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [reportsB1T, setReportsB1T] = useState(null);
  const [reportsB2T, setReportsB2T] = useState(null);
  const [reportsB3T, setReportsB3T] = useState(null);
  const [selectedPage, setSelectedPage] = useState({
    page: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const [selectedLocation, setSelectedLocation] = useState(null);

  const onLatLngClick = (params) => {
    setSelectedLocation(params);
  };

  const handleCloseMap = () => {
    setSelectedLocation(null);
  };

  useEffect(() => {
    fetchLastSevenDaysReportData();
  }, [selectedPage, location]);

  const fetchLastSevenDaysReportData = () => {
    try {
      const report = getUserReportByAPI(REPORT_D1T);
      const payload = {
        JWTid: appContext.userDetails.JWTid,
        reportId: report ? report.ReportId : DASHBOARD_D1T,
        appId: selectedUserApp.AppId,
        startDate: getDayBySubtractingNumDays(
          location.pathname === "/last-seven-days" ? 7 : 30
        ),
        endDate: getCurrentDateYYYYMMDD(),
        timezoneOffset: DEFAULT_TIMEZONE_OFFSET,
        ListView: "true",
        PageNumber: selectedPage.page,
        PageSize: selectedPage.pageSize,
      };
      setShowLoader(true);
      fetchDashboardReport(payload)
        .then((response) => {
          if (response.Status === 0) {
            setReportData(response);
            fetchDashboardB1TReport();
            fetchDashboardB2TReport();
            fetchDashboardB3TReport();
          } else {
            validateAnsShowServerException(
              toast,
              2,
              response.Status,
              appContext
            );
          }
        })
        .catch((err) => {
          showCatchedExceptionOnToast(toast, 2, err);
        })
        .finally(() => {
          setShowLoader(false);
        });
    } catch (err) {
      showCatchedExceptionOnToast(toast, 2, err);
    }
  };

  const onRefreshData = (data, type) => {
    setSelectedPage(data);
  };

  const fetchDashboardB1TReport = () => {
    try {
      const report = getUserReportByAPI(REPORT_B1T);
      const payload = {
        JWTid: appContext.userDetails.JWTid,
        reportId: report ? report.ReportId : BASE_B1T,
        appId: selectedUserApp.AppId,
        startDate: getDayBySubtractingNumDays(
          location.pathname === "/last-seven-days" ? 7 : 30
        ),
        endDate: getCurrentDateYYYYMMDD(),
        timezoneOffset: DEFAULT_TIMEZONE_OFFSET,
      };

      fetchActivities(payload)
        .then((response) => {
          if (response.Status === 0) {
            if (response.Data.Results) {
              const data = response.Data.Results;
              if (data.Failed === 0 && data.Passed === 0) {
                setReportsB1T({ results: [] });
              } else {
                const total = data.Failed + data.Passed;
                const percentFailed = (data.Failed / total) * 100;
                const percentSuccess = (data.Passed / total) * 100;

                const results = [
                  {
                    value: data.Failed,
                    title: "Failed " + percentFailed.toFixed(2) + "%",
                    color: "#D32F2F",
                  },
                  {
                    value: data.Passed,
                    title: "Passed " + percentSuccess.toFixed(2) + "%",
                    color: "#43A047",
                  },
                ];
                setReportsB1T({ results: results });
              }
            }
          } else {
            validateAndShowError(response.Status);
          }
        })
        .catch((err) => {
          showCatchedExceptionOnToast(toast, 2, err);
          setReportsB1T({ results: null });
        })
        .finally(() => {});
    } catch (err) {
      showCatchedExceptionOnToast(toast, 2, err);
    }
  };

  const fetchDashboardB2TReport = () => {
    try {
      const report = getUserReportByAPI(REPORT_B2T);
      const payload = {
        JWTid: appContext.userDetails.JWTid,
        reportId: report ? report.ReportId : BASE_B2T,
        appId: selectedUserApp.AppId,
        startDate: getDayBySubtractingNumDays(
          location.pathname === "/last-seven-days" ? 7 : 30
        ),
        endDate: getCurrentDateYYYYMMDD(),
        timezoneOffset: DEFAULT_TIMEZONE_OFFSET,
      };

      fetchDevices(payload)
        .then((response) => {
          if (response.Status === 0) {
            if (response.Data.Results) {
              const data = response.Data.Results;
              const results = data.map((item) => {
                return {
                  value: item.TotalNumber,
                  title: item.DeviceType,
                  color: getColorByDeviceType(item.DeviceType),
                };
              });
              setReportsB2T({ results: results });
            }
          } else {
            validateAndShowError(response.Status);
          }
        })
        .catch((err) => {
          showCatchedExceptionOnToast(toast, 2, err);
          setReportsB2T({ results: null });
        })
        .finally(() => {});
    } catch (err) {
      showCatchedExceptionOnToast(toast, 2, err);
    }
  };

  const fetchDashboardB3TReport = () => {
    try {
      const report = getUserReportByAPI(REPORT_B3T);

      const payload = {
        JWTid: appContext.userDetails.JWTid,
        reportId: report ? report.ReportId : BASE_B3T,
        appId: selectedUserApp.AppId,
        startDate: getDayBySubtractingNumDays(
          location.pathname === "/last-seven-days" ? 7 : 30
        ),
        endDate: getCurrentDateYYYYMMDD(),
        timezoneOffset: DEFAULT_TIMEZONE_OFFSET,
      };

      fetchFailedReasons(payload)
        .then((response) => {
          if (response.Status === 0) {
            if (response.Data.Results) {
              const data = response.Data.Results;
              const results = data.map((item) => {
                return {
                  id: item.ReasonForFailure,
                  value: item.TotalNumber,
                  label: item.ReasonForFailure,
                  color: getRandomHexColor(),
                };
              });
              setReportsB3T({ results: results });
            }
          } else {
            validateAndShowError(response.Status);
          }
        })
        .catch((err) => {
          showCatchedExceptionOnToast(toast, 2, err);
          setReportsB3T({ results: null });
        })
        .finally(() => {});
    } catch (err) {
      showCatchedExceptionOnToast(toast, 2, err);
    }
  };

  const validateAndShowError = (status) => {
    const tokenError = isTokenError(status);
    if (tokenError) {
      removeAuthInfo();
      appContext.setUserDetails(null);
    } else {
      showServerExceptionOnToast(toast, 1, status);
    }
  };

  const onActivityClick = () => {
    appContext.setSelectedTab(1);
  };

  const getB3TDataWithMappedColor = () => {
    if (!reportsB3T) return null;
    const values = reportsB3T?.results?.map((item) => {
      const itemFound = failedReasons.find(
        (reasonItem) => reasonItem.Code === item.label
      );
      if (itemFound) {
        return {
          id: item.id,
          value: item.value,
          color: itemFound.Color,
          title: itemFound.FriendlyName,
        };
      }else{
        return {
          id: null,
          value: null,
          color: null,
          title: null,
        };
      }
    });
    const sortedValues = values?.sort((a, b) => b.value - a.value);
    return { results: sortedValues };
  };

  const reportDuration = location.pathname === "/last-seven-days" ? "7" : "30";
  const theme = useTheme();
  return (
    <>
      <Typography
        sx={{
          fontSize: "1.3rem",
          fontWeight: "500",
          marginBottom: "0.5rem",
          color: theme.palette.text.primary,
        }}
      >
        Last {reportDuration} Days
      </Typography>

      <Box>
        <div
          style={{
            display: "flex",
            width: "100%",
            backgroundColor: "none",
            marginBottom: "2rem",
            color: theme.palette.text.primary,
          }}
        >
          <Grid
            sx={{ flexGrow: 1, backgroundColor: "none" }}
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 8, lg: 12 }}
            style={{ paddingTop: "1rem" }}
          >
            {reportsB1T && (
              <Grid item xs={6} sm={4} md={4} lg={4}>
                <ACTPieCard
                  data={reportsB1T}
                  title={"Activity"}
                  link={true}
                  activityHandler={onActivityClick}
                />
              </Grid>
            )}
            {reportsB2T && (
              <Grid item xs={6} sm={4} md={4} lg={4}>
                <ACTPieCard data={reportsB2T} title={"Devices"} />
              </Grid>
            )}
            {reportsB3T && fetchListReasonsForFail && (
              <Grid item xs={6} sm={4} md={4} lg={4}>
                <ACTPieCard
                  data={getB3TDataWithMappedColor()}
                  title={"Reasons for fail"}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </Box>

      {reportData && reportData.Data.Results.length > 0 && (
        <Box
          sx={{
            bgcolor: "#ffffff",
            borderRadius: "5px",
          }}
        >
          <CommonListView
            rows={reportData.Data.Results}
            columns={getSavedReportDataGridColumns(
              appContext.failedReasons,
              theme,
              onLatLngClick
            )}
            rowCount={reportData.Data.TotalRecords}
            pageSize={100}
            onRefreshData={(paginationModel) =>
              onRefreshData(paginationModel, 2)
            }
            getRowId={(row) => row.GeoReferenceId}
            height={"60vh"}
          />
        </Box>
      )}
      {selectedLocation && (
        <MapDialog
          params={selectedLocation}
          open={selectedLocation ? true : false}
          onClose={handleCloseMap}
        />
      )}
      {reportData && reportData.Data.Results.length === 0 && (
        <GenericError data={noDataReasons} />
      )}

      {showLoader && <Loader />}
      <ToastContainer position="top-center" autoClose={3000} />
    </>
  );
};

export default SavedReportContainer;
