import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";

const REPORTS = [
  {
    name: "Blocked Account",
    last_updated: "",
    link: "/blocked-accounts",
  },
  {
    name: "Blocked devices",
    last_updated: "",
    link: "/blocked-devices",
  },
  {
    name: "VPN detected",
    last_updated: "",
    link: "/vpn-detected",
  },
];

const ReportPageContainer = () => {
  const theme = useTheme();

  return (
    <>
      <Typography
        sx={{
          fontSize: "1.3rem",
          fontWeight: "500",
          marginBottom: ".5rem",
          color: theme.palette.text.primary,
        }}
      >
        Reports
      </Typography>

      <div style={{ display: "flex", margin: "1rem", flexDirection: "column" }}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Last Updated</TableCell>
                <TableCell>View</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {REPORTS.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                  >
                    {item.name}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                  >
                    {item.last_updated}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                  >
                    <div className="cluster-cell-item">
                      <Link
                        key={index}
                        to={item.link}
                        style={{
                          textDecoration: "underline",
                        }}
                        state={{ type: "device", id: item.DeviceId }}
                      >
                        VIEW REPORT
                      </Link>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default ReportPageContainer;
