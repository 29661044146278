import React from "react";
import { Link } from "react-router-dom";
import { formatDateBasedOnLocale } from "../../utils/DateUtils";
import {
  getColorCodeByReasonCode,
  getReasonsNameByCode,
} from "../../utils/CommonUtils";

export const getVPNDetectedReportDataGridColumns = (failedReasons, theme, onLatLngClick) => {
  return [
    {
      field: "AccountId",
      headerName: "Account ID",
      width: 180,
      display: "flex",
      renderCell: (cellValues) => {
        return (
          <Link
            to={"/summary/account/" + cellValues.row.AccountId}
            style={{
              color: theme.palette.text.primary,
              textDecoration: "underline",
            }}
            state={{ type: "account", id: cellValues.row.AccountId }}
          >
            {cellValues.row.AccountId}
          </Link>
        );
      },
    },
    {
      field: "DeviceId",
      headerName: "Device ID",
      width: 360,
      display: "flex",
      renderCell: (cellValues) => {
        return (
          <Link
            to={"/summary/device/" + cellValues.row.DeviceId}
            style={{
              color: theme.palette.text.primary,
              textDecoration: "underline",
            }}
            state={{ type: "device", id: cellValues.row.DeviceId }}
          >
            {cellValues.row.DeviceId}
          </Link>
        );
      },
    },
    {
      field: "DeviceType",
      headerName: "Device Type",
      width: 120,
      display: "flex",
    },
    {
      field: "Timestamp",
      headerName: "Date & Time",
      width: 180,
      display: "flex",
      valueFormatter: (params) => {
        return formatDateBasedOnLocale(params);
      },
    },
    {
      field: "ReasonForFailure",
      headerName: "Status",
      width: 180,
      display: "flex",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "7px",
            }}
          >
            <div
              style={{
                minWidth: "8px",
                minHeight: "8px",
                backgroundColor: getColorCodeByReasonCode(
                  params.row.ReasonForFailure[0],
                  failedReasons
                ),
                borderRadius: "4px",
              }}
            ></div>
            {getReasonsNameByCode(params.row.ReasonForFailure[0], failedReasons)}
          </div>
        );
      },
      valueGetter: (params) => {
        return getReasonsNameByCode(params, failedReasons);
      },
    },
    {
      field: "GeoReferenceId",
      headerName: "Transaction ID",
      width: 400,
      display: "flex",
    },
    {
      field: "location",
      headerName: "Location",
      width: 200,
      display: "flex",
      renderCell: (params) => {
        return (
          <>
           <button
            onClick={() => onLatLngClick(params)}
            style={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
          > 
            {params.row.Latitude + ", " + params.row.Longitude}
          </button>
          </>
        );
      },
    },
    {
      field: "ReasonForLookup",
      headerName: "Reason for Check",
      width: 200,
      display: "flex",
    },
    {
      field: "GeoLookUpType",
      headerName: "Accuracy",
      width: 100,
      display: "flex",
    },
  ];
};
