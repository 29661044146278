import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useTheme } from "@emotion/react";

export default function TestFieldStandardInput(props) {
  const { placeHolderText, handleUDID, clearInputstate, enteredText } = props;
  const [selectData, setData] = React.useState([]);
  const theme = useTheme();
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setData(
      // On fill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    handleUDID(value);
  };

  React.useEffect(() => {
    setData([]);
  }, [clearInputstate]);

  return (
    <Box
      component="form"
      value={selectData}
      onChange={handleChange}
      renderValue={(selected) => {
        if (selected.length === 0) {
          return <p>{placeHolderText}</p>;
        }
        return selected.join();
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        label={placeHolderText}
        variant="standard"
        value={enteredText ? enteredText : ""}
        sx={{
          '& .MuiInputBase-input': {
            color: theme.palette.text.primary + '!important', // Input text color
          },
        }}
      />
    </Box>
  );
}
