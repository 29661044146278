import React, { useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CloseButtonFilled from "../shared/Buttons/CloseButtonFilled/CloseButtonFilled";
import "./SearchModalStyles.scss";
import DashboardDatepicker from "../shared/DashboardDatepicker";
import SelectDropdownMenu from "../shared/SelectDropdownMenu/SelectDropdownMenu";
import TestFieldStandardInput from "../shared/TestFieldStandardInput";
import { getDateFromString } from "../../utils/DateUtils";
import { useTheme } from "@emotion/react";
import ACTSavedReportDialog from "../shared/ACTSavedReportDialog";
import Loader from "../loader/Loader";
import { X } from "@mui/icons-material";

const accordionStyle = {
  // "& .MuiButtonBase-root": {
  //   BorderBottom: "12",
  // },
  // "&.MuiAccordion-root:before": {
  //     backgroundColor: "red",
  //     BorderTop: "2",
  // }
  // "& .MuiAccordionDetails-root": {
  //   boxShadow: "none",
  //   backgroundColor: "#000"
  // },
  // "& .MuiPaper-root-MuiAccordion-root": {
  //   boxShadow: "none",
  //   backgroundColor: "#000"
  // }
};
const AdvancedSearch = (props) => {
  const {
    onDateSelect,
    isShowDatePickers,
    saveSearch,
    clearAll,
    searchData,
    toggleAdvanced,
    transactionPeriodStartDate,
    advancedSearchFormValues,
    advancedSearchData,
    allFlag,
    deviceTypes,
    devicePlaceholder,
    transPeriod,
    transPlaceholder,
    UDIDPlaceholder,
    clearInputstate,
    reasonForFailureFlags,
    isTransDateSelected,
  } = props;
  const openSaveDialog = () => {
    setShowSavedReportDialog(true);
  };
  const theme = useTheme();
  const [showLoader, setShowLoader] = useState("");
  const [showSavedReportDialog, setShowSavedReportDialog] = useState(false);
  const getBackgroundStyle = (theme) => ({
    background: theme.palette.mode === "dark" && "#1c1c1c",
  });

  return (
    <div className="advanced-search-panel">
      <div className="modal-advanced-close">
        <CloseButtonFilled
          type={"Submit"}
          isDisabled={false}
          clickHandler={toggleAdvanced}
        />
      </div>
      <div>
        <Accordion
          elevation={0}
          defaultExpanded={true}
          sx={{
            ...accordionStyle,
            borderRadius: 0,
            borderBottom: 0,
            borderTop: 0,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            Transaction period*
          </AccordionSummary>
          <AccordionDetails>
            <div className="accordion-menu-container">
              <SelectDropdownMenu
                options={transPeriod}
                placeHolderText={transPlaceholder}
                handleSelect={onDateSelect}
                // clearInputstate={clearInputstate}
                selectedValue={advancedSearchData?.TransPeriod}
              />
              <div className="date-spacer"></div>
              <div className="accordion-spacer"></div>
            </div>
            {isShowDatePickers && (
              <div className="accordion-menu-container mobile-style">
                <div
                  className="accordion-menu accordion-border"
                  style={getBackgroundStyle(theme)}
                >
                  <DashboardDatepicker
                    //  onRefreshData={(date) => onDateSelect(date, "startDate")}
                    onRefreshData={(date) =>
                      transactionPeriodStartDate(date, "startDate")
                    }
                    defaultDate={getDateFromString(
                      advancedSearchData?.startDate
                    )}
                  />
                </div>
                <div className="date-to">to</div>
                <div
                  className="accordion-menu accordion-border"
                  style={getBackgroundStyle(theme)}
                >
                  <DashboardDatepicker
                    name="endDate"
                    // onRefreshData={(date) => onDateSelect(date, "endDate")}
                    // onRefreshData={transactionPeriodStartDate}
                    onRefreshData={(date) =>
                      transactionPeriodStartDate(date, "endDate")
                    }
                    defaultDate={getDateFromString(advancedSearchData?.endDate)}
                  />
                </div>
              </div>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          sx={{
            ...accordionStyle,
            borderRadius: 0,
            borderBottom: 0,
            borderTop: 0,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            Device type
          </AccordionSummary>
          <AccordionDetails>
            <div className="accordion-menu-container mobile-gap">
              <SelectDropdownMenu
                options={deviceTypes}
                placeHolderText={devicePlaceholder}
                handleSelect={(value) =>
                  advancedSearchFormValues(value, "DeviceType")
                }
                // clearInputstate={clearInputstate}
                selectedValue={advancedSearchData?.DeviceType}
              />
              <div className="date-spacer"></div>
              <div className="accordion-spacer">
                <TestFieldStandardInput
                  placeHolderText={UDIDPlaceholder}
                  handleUDID={(value) =>
                    advancedSearchFormValues(value, "DeviceId")
                  }
                  // clearInputstate={clearInputstate}
                  enteredText={advancedSearchData?.DeviceId}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          sx={{
            ...accordionStyle,
            borderRadius: 0,
            borderBottom: 0,
            borderTop: 0,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            Customers located by
          </AccordionSummary>
          <AccordionDetails
            onChange={(inp) =>
              advancedSearchFormValues(
                inp.target.value,
                "GeoLookUpType",
                inp.target.checked
              )
            }
          >
            <div className="inner-accordion">
              <FormGroup>
                {allFlag &&
                  allFlag.map((item, index) => {
                    return (
                      <FormControlLabel
                        key={index}
                        control={<Checkbox />}
                        label={item.label}
                        value={item.key}
                        color="default"
                        checked={item.selected}
                      />
                    );
                  })}
              </FormGroup>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          sx={{
            ...accordionStyle,
            borderRadius: 0,
            borderBottom: 0.1,
            borderTop: 0,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4-content"
            id="panel4-header"
          >
            Reason for failure
          </AccordionSummary>
          <AccordionDetails
            onChange={(inp) =>
              advancedSearchFormValues(
                inp.target.value,
                "ReasonForFailure",
                inp.target.checked
              )
            }
          >
            <div className="inner-accordion">
              <FormGroup>
                {reasonForFailureFlags &&
                  reasonForFailureFlags.map((item, index) => {
                    return (
                      <FormControlLabel
                        key={index}
                        control={<Checkbox />}
                        label={item.label}
                        value={item.key}
                        color="default"
                        checked={item.selected}
                      />
                    );
                  })}
              </FormGroup>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>

      <div
        className="save-container"
        style={
          {
            // backgroundColor: theme.palette.mode === 'dark' ? '#1c1c1c' : '#d8d8d8',
            // color: theme.palette.text.primary
          }
        }
      >
        <div className="save-panel-left">
          <div className="save-panel-left-block">
            {!isTransDateSelected ? (
              <>
                <div className="save-icon-block">
                  <SaveIcon
                    className="save-icon"
                    style={{
                      color: theme.palette.text.secondary,
                    }}
                  />
                </div>
                <button
                  className="save-search-button-disabled"
                  style={{
                    color: theme.palette.text.secondary,
                  }}
                >
                  save search
                </button>
              </>
            ) : (
              <>
                <div className="save-icon-block">
                  <SaveIcon
                    className="save-icon"
                    style={{
                      color: theme.palette.text.primary,
                    }}
                  />
                </div>
                <button
                  className="save-search-button"
                  onClick={openSaveDialog}
                  style={{
                    color: theme.palette.text.primary,
                  }}
                >
                  save search
                </button>
              </>
            )}
          </div>
        </div>
        <div className="save-panel-right">
          <div className="save-panel-right-block">
            {!isTransDateSelected ? (
              <>
                <button
                  className="clear-all-button-disabled"
                  style={{
                    color: theme.palette.text.secondary,
                  }}
                >
                  clear all
                </button>
                <button className="search-button-disabled">
                  search
                </button>
              </>
            ) : (
              <>
                <button
                  className="clear-all-button"
                  onClick={clearAll}
                  style={{
                    color: theme.palette.text.primary,
                  }}
                >
                  clear all
                </button>
                <button className="search-button" onClick={searchData}>
                  search
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      <ACTSavedReportDialog
        openDialog={showSavedReportDialog}
        closeHandler={setShowSavedReportDialog}
        saveSearchHandler={saveSearch}
      />
      {showLoader && <Loader />}
    </div>
  );
};

export default AdvancedSearch;
