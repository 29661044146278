import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { IconButton } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";
import {
  getCurrentDateYYYYMMDD,
  getDateBySubtractMonth,
} from "../../utils/DateUtils";
import dayjs from "dayjs";
import DashboardDatepickerMobile from "../shared/DashboardDatepickerMobile";
import { useTheme } from "@emotion/react";

const TransactionsToolbarMobile = (props) => {
  const {
    summaryType,
    summaryId,
    exportHandler,
    printHandler,
    refreshDataHandler,
  } = props;
  const theme = useTheme();
  const [startDate, setStartDate] = useState(getDateBySubtractMonth(3));
  const [endDate, setEndDate] = useState(getCurrentDateYYYYMMDD());

  const onDateChange = (value, type) => {
    if (type === 1) {
      setStartDate(value);
    } else if (type === 2) {
      setEndDate(value);
    }

    if (startDate && endDate && refreshDataHandler) {
      refreshDataHandler({ startDate, endDate }, 1);
    }
  };

  return (
    <Grid
      sx={{ flexGrow: 1 }}
      container
      spacing={2}
      style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
    >
      <Grid item xs={6} sm={3}>
        <DashboardDatepickerMobile
          onRefreshData={onDateChange}
          type={1}
          defaultDate={dayjs().subtract(3, "month")}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <DashboardDatepickerMobile
          onRefreshData={onDateChange}
          type={2}
          defaultDate={dayjs()}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: ".5rem",
          }}
        >
          {summaryType === "device" && (
            <div
              style={{
                display: "flex",
                fontSize: "1rem",
                fontWeight: "500",
                flexDirection: "row",
                marginRight: "1rem",
              }}
            >
              {"Device:"}
              <div
                style={{
                  fontSize: "1rem",
                  fontWeight: "400",
                  marginLeft: "0.25rem",
                }}
              >
                {summaryId}
              </div>
            </div>
          )}
          {summaryType === "account" && (
            <div
              style={{
                display: "flex",
                fontSize: "1rem",
                fontWeight: "500",
                flexDirection: "row",
                marginRight: "1rem",
              }}
            >
              {"Account:"}
              <div
                style={{
                  fontSize: "1rem",
                  fontWeight: "400",
                  marginLeft: "0.25rem",
                }}
              >
                {summaryId}
              </div>
            </div>
          )}

          <IconButton onClick={printHandler} disabled>
            <PrintIcon />
          </IconButton>
          <IconButton onClick={exportHandler} sx={{
            backgroundColor: theme.palette.mode === 'dark' ? '#1c1c1c' : '#d8d8d8',
            color: theme.palette.text.primary
          }}>
            <DownloadIcon />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TransactionsToolbarMobile;
