import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const LogOutButton = styled(Button)(({ theme, btnState }) => ({
    color: btnState ? "#1e86f0" : "#737373",
    fontSize: "0.65rem",
    fontWeight: "600",
    border: btnState ? "0.065rem #1e86f0 solid" : "0.065rem #737373 solid",
    borderRadius: "0.2rem",
    backgroundColor: btnState ? "#ffffff" : "none",
    padding: "0.3rem",
    "&:hover": {
      backgroundColor: "#ffffff",
      border: "0.065rem #1e86f0 solid",
      color: "#1e86f0",
    },
    "&:active": {
      backgroundColor: "#ffffff",
      border: "0.065rem #1e86f0 solid",
      color: "#1e86f0",
    },
    "&:focus": {
      backgroundColor: "#ffffff",
    },
  }));

  export default LogOutButton;