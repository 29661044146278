import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import {
  APIProvider,
  Map as GoogleMap,
  useMap,
  AdvancedMarker,
  InfoWindow,
} from "@vis.gl/react-google-maps";
import Supercluster from "supercluster";
import { getMapConfig } from "../../../../config/MapConfig";
import { DEFAULT_DIALOG_ZOOM_LEVEL } from "../../../../constants/Constants";
import { AppContext } from "../../../../context/AppContext";
import { getColorCodeByReasonCode } from "../../../../utils/CommonUtils";
import ACTTransaction from "../../../shared/ACTTransaction";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { throttle } from "lodash";
import FeatureMarker from "../superclustermap/FeatureMarker";

const DialogSingleMap = (props) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const appContext = useContext(AppContext);
  const { selectedUserApp } = appContext;
  const { data } = props;
  const customtextColorForDarkmode = (theme) => ({
    color: theme.palette.mode === "dark" && "#ffffff",
  });
  const position = { lat: data.Latitude, lng: data.Longitude };

  const { APIKey, MapId } = getMapConfig();

  // Generate a unique key based on selectedApp to force remount
  const mapKey = `${selectedUserApp.AppId}-${position.lat}-${position.lng}`;

  return (
    <APIProvider apiKey={APIKey}>
      <div style={{ height: "100%", width: "100%" }}>
        <GoogleMap
          key={mapKey}
          defaultZoom={DEFAULT_DIALOG_ZOOM_LEVEL}
          defaultCenter={position}
          mapId={MapId}
          gestureHandling={"auto"}
          disableDefaultUI={true}
          zoomControl={true}
          streetViewControl={true}
          fullscreenControl={true}
        >
          {data && (
            <Markers
              makerData={[data]}
              reasonsForFail={appContext.failedReasons}
            />
          )}
        </GoogleMap>
      </div>
    </APIProvider>
  );
};

const Markers = ({ makerData, reasonsForFail }) => {
  const map = useMap();
  const [clusters, setClusters] = useState([]);
  const [groupedPoints, setGroupedPoints] = useState([]);
  const [points, setPoints] = useState(makerData);
  const [selectedPin, setSelectedPin] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [moreItems, setMoreItems] = useState(null);

  useEffect(() => {
    const grouped = new Map(); // Use Map to group by unique lat,lng pairs

    points.forEach((point) => {
      const { Latitude: lat, Longitude: lng } = point;
      const positionKey = `${lat},${lng}`; // Create a unique key based on lat,lng

      if (!grouped.has(positionKey)) {
        // If this lat,lng combination isn't in the Map, add it with an array containing this point
        grouped.set(positionKey, [point]);
      } else {
        // If this lat,lng combination already exists, add this point to that group
        grouped.get(positionKey).push(point);
      }
    });

    // Convert the grouped Map to an array of clusters
    const groupedArray = Array.from(grouped.values());
    setGroupedPoints(groupedArray); // Update the state with the grouped points
  }, [points]);

  useEffect(() => {}, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const supercluster = useRef(
    new Supercluster({
      radius: 80,
      maxZoom: 16,
    })
  );

  useEffect(() => {
    setPoints(makerData);
  }, [makerData]);

  const updateClusters = useCallback(() => {
    if (!map) return;

    const bounds = map.getBounds();
    const zoom = map.getZoom();

    if (bounds) {
      const clusters = supercluster.current.getClusters(
        [
          bounds.getSouthWest().lng(),
          bounds.getSouthWest().lat(),
          bounds.getNorthEast().lng(),
          bounds.getNorthEast().lat(),
        ],
        zoom
      );
      setClusters(clusters);
    }
  }, [map]);

  useEffect(() => {
    if (!map) return;
    const throttledUpdateClusters = throttle(updateClusters, 200); // Throttle calls
    const listener = map.addListener("bounds_changed", updateClusters);
    return () => {
      listener.remove();
      throttledUpdateClusters.cancel(); // Cleanup
    };
  }, [map, updateClusters]);

  useEffect(() => {
    if (!points) return;
    supercluster.current.load(
      points.map((point) => {
        return {
          type: "Feature",
          properties: { cluster: false, ...point },
          geometry: {
            type: "Point",
            coordinates: [point.Longitude, point.Latitude],
          },
        };
      })
    );
    updateClusters();
  }, [points]);

  const getClusterColor = (pointCount, zoom) => {
    const highDensityThreshold = 100;
    const lowDensityThreshold = 10;

    if (pointCount >= highDensityThreshold) {
      const svg =
        '<svg fill="#e65100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240"><circle cx="120" cy="120" opacity="1" r="70" /><circle cx="120" cy="120" opacity=".3" r="90" /><circle cx="120" cy="120" opacity=".2" r="110" /><circle cx="120" cy="120" opacity=".1" r="130" /></svg>';
      const encodedSvg = encodeURIComponent(svg.trim());
      return `url("data:image/svg+xml,${encodedSvg}")`;
    } else if (pointCount >= lowDensityThreshold) {
      const svg =
        '<svg fill="#f57c00" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240"><circle cx="120" cy="120" opacity="1" r="70" /><circle cx="120" cy="120" opacity=".3" r="90" /><circle cx="120" cy="120" opacity=".2" r="110" /><circle cx="120" cy="120" opacity=".1" r="130" /></svg>';
      const encodedSvg = encodeURIComponent(svg.trim());
      return `url("data:image/svg+xml,${encodedSvg}")`;
    } else {
      const svg =
        '<svg fill="#ffa726" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240"><circle cx="120" cy="120" opacity="1" r="70" /><circle cx="120" cy="120" opacity=".3" r="90" /><circle cx="120" cy="120" opacity=".2" r="110" /><circle cx="120" cy="120" opacity=".1" r="130" /></svg>';
      const encodedSvg = encodeURIComponent(svg.trim());
      return `url("data:image/svg+xml,${encodedSvg}")`;
    }
  };

  const onMarkerClick = (marker, item) => {
    setSelectedPin({ marker, item });
  };

  const handleInfoWindowClose = useCallback(() => {
    setSelectedPin(null);
  }, []);

  const onCopyData = (text) => {
    navigator.clipboard.writeText(text);
  };

  const onViewSimilarLocations = (e, items) => {
    setAnchorEl(e.currentTarget);
    setMoreItems(items);
  };

  if (!points) {
    return <></>;
  }
  let isRendering = false;
  // console.log(selectedPin);

  return (
    <>
      {clusters.map((cluster) => {
        const [lng, lat] = cluster.geometry.coordinates;
        const { cluster: isCluster, point_count: pointCount } =
          cluster.properties;
        const { properties } = cluster;

        if (isCluster) {
          const color = getClusterColor(pointCount, map.getZoom());

          return (
            <AdvancedMarker
              key={`cluster-${cluster.id}`}
              position={{ lat, lng }}
              label={`${pointCount}`}
              onClick={() => {
                const expansionZoom = Math.min(
                  supercluster.current.getClusterExpansionZoom(cluster.id),
                  20
                );
                map.setZoom(expansionZoom);
                map.panTo({ lat, lng });
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "3rem",
                  height: "3rem",
                  backgroundImage: color,
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              >
                <span style={{ fontSize: ".60rem", color: "white" }}>
                  {pointCount}
                </span>
              </div>
            </AdvancedMarker>
          );
        }

        //This function has added to fix lag issue with multiple pins in same location
        if (map.zoom >= 17) {
          if (isRendering) return;
          isRendering = true;
          return groupedPoints?.map((clusters, clusterIndex) => {
            const isGrouped = clusters.length >= 2;
            const primaryPoint = clusters[0];

            // Extract latitude and longitude from clusters
            const lng = clusters[0]?.Longitude;
            const lat = clusters[0]?.Latitude;
            // Validate lat and lng to ensure they are valid numbers
            if (isNaN(lat) || isNaN(lng)) {
              console.warn(
                `Invalid coordinates for marker at index ${clusterIndex}:`,
                { lat, lng, primaryPoint }
              );
              return null; // Skip rendering this marker if coordinates are invalid
            }
            const reasonCode =
              primaryPoint?.ReasonForFailure[0] === undefined
                ? primaryPoint?.ReasonForFailure
                : primaryPoint?.ReasonForFailure[0];
            const markerColor = getColorCodeByReasonCode(
              reasonCode,
              reasonsForFail
            );

            const markerLabel = isGrouped ? `${clusters[0].length}` : "";
            return (
              <FeatureMarker
                key={primaryPoint?.GeoReferenceId || clusterIndex}
                position={{ lat, lng }}
                onMarkerClick={onMarkerClick}
                item={primaryPoint}
                bgColor={markerColor}
                label={markerLabel}
                isGrouped={isGrouped}
              />
            );
          });
        }
        isRendering = false;

        //For now this pins wont be shown if zoom level reaches 17
        if (map.zoom <= 16) {
          return (
            <FeatureMarker
              key={properties?.GeoReferenceId}
              position={{ lat, lng }}
              onMarkerClick={onMarkerClick}
              item={properties}
              bgColor={getColorCodeByReasonCode(
                properties.ReasonForFailure[0] === undefined
                  ? properties.ReasonForFailure
                  : properties.ReasonForFailure[0],
                reasonsForFail
              )}
            />
          );
        }
      })}

      {selectedPin && (
        <InfoWindow
          anchor={selectedPin.marker}
          onCloseClick={handleInfoWindowClose}
        >
          {selectedPin?.item && (
            <ACTTransaction
              selectedGeoRefId={selectedPin?.item.GeoReferenceId}
              points={points}
              failedReasons={reasonsForFail}
              onCopyData={onCopyData}
              onViewSimilarLocations={onViewSimilarLocations}
            />
          )}
        </InfoWindow>
      )}
    </>
  );
};

export default DialogSingleMap;
