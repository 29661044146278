import React, { useState, useRef, useContext, useEffect } from "react";
import {
  DataGrid,
  gridClasses,
  useGridApiRef
} from "@mui/x-data-grid";
import { alpha, styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import "./CommonListViewStyles.scss";
import { useTheme } from '@mui/material/styles';
import CustomToolbarButton from './CusomToolbarButton';

import {
  getCurrentDateYYYYMMDDWithTime,
} from "../../utils/DateUtils";
import { AppContext } from "../../context/AppContext";
import {
  getLocatedNameByCode,
  getValidationStatus
} from "../../utils/CommonUtils";
import { useLocation } from 'react-router-dom';

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.mode === "dark" ? "#1c1c1c" : "#ffffff",
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: alpha(theme.palette.grey[400], ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
          theme.palette.action.selectedOpacity +
          theme.palette.action.hoverOpacity
        ),
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-cell': {
            padding: '2px 4px', // Further reduce padding for compact cells
            fontSize: '0.875rem', // Slightly smaller font for compactness
          },
          '& .MuiDataGrid-columnHeaders': {
            height: '32px', // Reduce header height
            padding: '0px 4px', // Reduce header padding
            fontSize: '0.875rem', // Compact header font size
          },
          '& .MuiDataGrid-row': {
            maxHeight: '32px !important', // Reduce row height even further
            minHeight: '32px !important', // Force minimum row height to 32px
          },
          '& .MuiDataGrid-virtualScroller': {
            minHeight: '32px', // Apply similar min-height for the scroller
          },
          '& .MuiDataGrid-footerContainer': {
            minHeight: '32px', // Reduce the height of footer if used
          },
        },
      },
      defaultProps: {
        density: 'compact', // Set default density to compact
      },
    },
  },
}));

const CommonListView = ({
  rows,
  columns,
  rowCount,
  pageSize,
  onRefreshData,
  getRowId,
  height = "55vh",
  checkboxSelection = true,
  paginationMode = "server",
  summaryType,
  summaryId,
  data,
}) => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize,
  });
  const theme = useTheme();
  // const apiRef = useRef();
  const apiRef = useGridApiRef();
  const appContext = useContext(AppContext);
  const gridRef = useRef(null); // Ref for the wrapper div
  let location = useLocation();
  let pathName = location.pathname;

  const [selectedRows, setSelectedRows] = useState([]); // Store selected rows
  // Handle row selection changes
  const handleSelectionModelChange = (selectionModel) => {
    const selected = rows.filter((row) => selectionModel.includes(getRowId(row)));
    setSelectedRows(selected);
  };

  const [filterModel, setFilterModel] = useState(null);
  const [filteredRow, setFilteredRows] = useState();
  const [noResults, setNoResults] = useState(false);
  const handleFilterModelChange = (newFilterModel) => {
    setFilterModel(newFilterModel);  // Store the filter model in the state
  };

  const handleExportVisibleRows = () => {
    if (!apiRef.current) return;
    if (filterModel?.items?.length <= 0) return;

    // Check if any filter is applied
    const isFilterApplied = filterModel?.items?.length > 0;

    // Step 1: Get all rows
    const allRows = Array.from(apiRef.current.getRowModels().values());
    // Step 2: Manually apply filter based on the stored filterModel
    const filteredRows = isFilterApplied ? allRows.filter((row) => {
      return filterModel?.items.every((filter) => {
        let columnValue; // Ensure columnValue is a string for comparisons
        const filterValue = filter.value?.toString().toLowerCase() || '';      // Ensure filterValue is a string for comparisons
        let itemFound;
        if (filter.field === "ReasonForFailure") {
          itemFound = appContext.failedReasons?.find(
            (reasonItem) => String(reasonItem.Code) === String(row.ReasonForFailure)
          );
          columnValue = itemFound?.FriendlyName?.toString().toLowerCase() || '';
        } else if (filter.field === 'GeoLookUpType') {
          let LocatedBy = getLocatedNameByCode(row?.GeoLookUpType);
          columnValue = LocatedBy?.toString().toLowerCase() || '';
        } else if (filter.field === 'LocatedBy') {
          let LocatedName = getLocatedNameByCode(row.LocatedBy)
          columnValue = LocatedName?.toString().toLowerCase() || '';
        } else {
          columnValue = row[filter.field]?.toString().toLowerCase() || '';
        }

        switch (filter.operator) {
          case 'contains':
            return columnValue.includes(filterValue);
          case 'equals':
            return columnValue === filter.value;
          case 'startsWith':
            return columnValue.startsWith(filterValue);
          case 'endsWith':
            return columnValue.endsWith(filterValue);
          case 'isEmpty':
            return columnValue === '';
          case 'isNotEmpty':
            return columnValue !== '';
          case 'isAnyOf':
            // Assuming `filter.value` is an array for `isAnyOf`
            // return Array.isArray(filter.value) && filter.value.includes(columnValue);
            return true;
          default:
            return true;
        }
      });
    }) : allRows;

    // Step 4: Only update filtered rows if there is a change
    setFilteredRows((prevFilteredRows) => {
      const isDifferent = JSON.stringify(prevFilteredRows) !== JSON.stringify(filteredRows);
      return isDifferent ? filteredRows : prevFilteredRows;
    });

    // Update `noResults` only if filters are applied
    setNoResults(isFilterApplied && filteredRows.length === 0);
  };



  const handlePrint = () => {
    if (noResults || (filteredRow && filteredRow.length === 0)) {
      console.warn("No rows available to print.");
      return;
    }
    // Determine whether to print selected rows or all rows
    // const rowsToPrint = selectedRows.length > 0 ? selectedRows : rows;
    // Determine whether to print filtered rows, selected rows, or all rows
    const rowsToPrint = filteredRow && filteredRow.length > 0
      ? filteredRow
      : (selectedRows.length > 0 ? selectedRows : rows);
    if (rowsToPrint.length === 0) {
      console.warn("No rows available to print.");
      return;
    }

    // Create table HTML for printing
    let tableHTML = '<table border="1" cellspacing="0" cellpadding="5" style="width: 100%; table-layout: fixed;"><thead><tr>';

    // Add column headers
    columns.forEach((column) => {
      tableHTML += `<th style="word-wrap: break-word;">${column.headerName}</th>`;
    });
    tableHTML += '</tr></thead><tbody>';

    // Add rows (either selected rows or all rows)
    rowsToPrint.forEach((row) => {
      tableHTML += '<tr>';
      columns.forEach((column) => {
        // Access data using the column field
        let cellValue = row[column.field];

        // Handle specific cases
        if (column.field === 'DeviceIds' && Array.isArray(cellValue)) {

          cellValue = cellValue.join(', '); // Join array into a string
        }

        if (column.field === 'ReasonForFailure') {
          const itemFound = appContext.failedReasons?.find(
            (reasonItem) => String(reasonItem.Code) === String(row.ReasonForFailure)
          );
          cellValue = itemFound?.FriendlyName;
        }

        if (column.field === 'GeoLookUpType') {
          let LocatedBy = getLocatedNameByCode(row?.GeoLookUpType);
          cellValue = LocatedBy;
        }

        if (column.field === 'LocatedBy') {
          let LocatedName = getLocatedNameByCode(row.LocatedBy)
          cellValue = LocatedName;
        }

        let latandlon = row.Latitude && row.Longitude ? `${row.Latitude}, ${row.Longitude}` : 'Unknown';
        if (column.field === 'location') {
          cellValue = latandlon;
        }

        cellValue = (cellValue !== undefined && cellValue !== null) ? cellValue : '';

        if (column.field === 'AccountID') {
          cellValue = row.AccountId || '';
        }

        if (pathName.includes("search") && column.field === 'AccountID') {
          cellValue = row.AccountID || '';
        }

        //Search data grid list Status
        if (column.field === 'Validated') {
          let status = getValidationStatus(row.Validated);
          cellValue = status;
        }

        tableHTML += `<td style="word-wrap: break-word;">${cellValue}</td>`;
      });
      tableHTML += '</tr>';
    });
    tableHTML += '</tbody></table>';

    // Create a hidden iframe
    const printIframe = document.createElement('iframe');
    printIframe.style.position = 'absolute';
    printIframe.style.width = '0px';
    printIframe.style.height = '0px';
    printIframe.style.border = 'none';

    // Append iframe to body
    document.body.appendChild(printIframe);

    const doc = printIframe.contentWindow.document;
    doc.open();
    doc.write(`
      <html>
        <head>
          <title>ACT-GEOSAFE</title>
          <style>
            body {
              font-family: Arial, sans-serif;
            }
            table {
              width: 100%;
              border-collapse: collapse;
            }
            th, td {
              border: 1px solid black;
              padding: 8px;
              text-align: left;
              word-wrap: break-word;
            }
            th {
              background-color: #f2f2f2;
            }
          </style>
        </head>
        <body>${tableHTML}</body>
      </html>
    `);
    doc.close();

    // Trigger the print
    printIframe.contentWindow.focus();
    printIframe.contentWindow.print();

    // Remove the iframe after printing
    setTimeout(() => {
      document.body.removeChild(printIframe);
    }, 1000);
  };

  const handleCsvExport1 = (dataList, summaryType, summaryId) => {
    if (noResults || (filteredRow && filteredRow.length === 0)) {
      console.warn("No rows available to print.");
      return;
    }
    const strDateTime = getCurrentDateYYYYMMDDWithTime();
    let dynamicData = filteredRow || dataList;
    let fileName;

    // Determine file name and data source based on pathname
    if (pathName.includes("search")) {
      fileName = 'search_' + strDateTime;
      dynamicData = filteredRow || rows;
    } else if (pathName.includes("dashboard")) {
      fileName = 'dashboard_' + strDateTime;
      dynamicData = filteredRow || rows;
    } else if (pathName.includes("blocked-accounts")) {
      fileName = 'blocked-accounts_' + strDateTime;
      dynamicData = filteredRow || rows;
    } else if (pathName.includes("blocked-devices")) {
      fileName = 'blocked-devices_' + strDateTime;
      dynamicData = filteredRow || rows;
    } else if (pathName.includes("last-seven-days")) {
      fileName = 'last-seven-days_' + strDateTime;
      dynamicData = filteredRow || rows;
    } else if (pathName.includes("last-thirty-days")) {
      fileName = 'last-thirty-days_' + strDateTime;
      dynamicData = filteredRow || rows;
    } else {
      fileName = summaryId + '_' + summaryType + '_' + strDateTime;
    }

    // Modify the data to format the location and handle other field transformations
    const modifiedDataList = dynamicData.map((row) => {
      const modifiedRow = { ...row };

      // Handle the 'location' field by concatenating Latitude and Longitude
      columns.forEach((column) => {
        if (column.field === 'location') {
          // Ensure both latitude and longitude are valid and combine them, otherwise mark as 'Unknown'
          const lat = row.Latitude !== null && row.Latitude !== undefined ? row.Latitude : 'Unknown';
          const lon = row.Longitude !== null && row.Longitude !== undefined ? row.Longitude : 'Unknown';

          modifiedRow.Location = lat !== 'Unknown' && lon !== 'Unknown' ? `"${lat}, ${lon}"` : 'Unknown';
          delete modifiedRow.Latitude; // Remove individual Latitude field
          delete modifiedRow.Longitude; // Remove individual Longitude field
        }

        if (column.field === 'ReasonForFailure') {
          const itemFound = appContext.failedReasons?.find(
            (reasonItem) => String(reasonItem.Code) === String(row.ReasonForFailure)
          );
          modifiedRow.ReasonForFailure = itemFound?.FriendlyName || 'Unknown';
        }

        if (column.field === 'LocatedBy') {
          let LocatedName = getLocatedNameByCode(row.LocatedBy)
          modifiedRow.LocatedBy = LocatedName;
        }

        if (column.field === 'GeoLookUpType') {
          let LocatedBy = getLocatedNameByCode(row.GeoLookUpType);
          modifiedRow.GeoLookUpType = LocatedBy;
        }

        //Search data grid list Status
        if (column.field === 'Validated') {
          let status = getValidationStatus(row.Validated);
          modifiedRow.Validated = status;
        }

        if (column.field === 'AccountID') {
          modifiedRow.AccountID = row.AccountId;
        }

        if (pathName.includes("search") && column.field === 'AccountID') {
          modifiedRow.AccountID = row.AccountID;
        }
      });

      return modifiedRow;
    });

    // Generate CSV content manually
    let csvContent = columns.map(col => col.headerName).join(",") + "\n"; // Add headers
    csvContent += modifiedDataList.map(row => {
      return columns.map(col => {
        // Return the correct values for each field, defaulting to empty string if not present
        if (col.field === 'location') {
          return row.Location || 'Unknown'; // Use 'Unknown' if the Location is missing
        }
        return row[col.field] !== undefined ? row[col.field] : ''; // Handle other fields safely
      }).join(",");
    }).join("\n");

    // Create a blob and trigger CSV download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}.csv`;
    link.click();
  };



  return (
    <div
      ref={gridRef}
      style={{
        height: height, width: "100%", backgroundColor: theme.palette.mode === 'dark' ? '#1c1c1c' : '#d8d8d8',
        color: theme.palette.text.primary,
      }}
    >
      <StripedDataGrid
        apiRef={apiRef}
        onFilterModelChange={handleFilterModelChange}
        id="yourGridId"
        rows={rows}
        columns={columns}
        rowCount={rowCount}
        pagination
        disableRowSelectionOnClick
        paginationMode={paginationMode}
        checkboxSelection={checkboxSelection}
        onStateChange={(params) => {
          const api = params.api; // Get access to the API here
          // console.log("params",params)
          handleExportVisibleRows(api); // Pass the api to your export function
        }}
        slots={{
          toolbar: () =>
            <CustomToolbarButton
              onPrint={handlePrint}
              summaryType={summaryType}
              summaryId={summaryId}
              summaryData={data}
              handleCsvExport1={handleCsvExport1}
              onRowSelectionModelChange={handleSelectionModelChange}
            />
        }}
        getRowId={getRowId}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
        pageSizeOptions={[pageSize]}
        paginationModel={paginationModel}
        onPaginationModelChange={(newPaginationModel) => {
          setPaginationModel(newPaginationModel);
          if (onRefreshData) {
            onRefreshData({
              ...newPaginationModel,
              page: newPaginationModel.page + 1,
            });
          }
        }}
        onRowSelectionModelChange={handleSelectionModelChange} // Track selection
        sx={{
          "& .MuiTablePagination-displayedRows": {
            color: theme.palette.text.primary,
          },
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
        }}
        getRowHeight={() => "auto"}
      />
    </div>
  );
};

CommonListView.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  rowCount: PropTypes.number,
  pageSize: PropTypes.number.isRequired,
  onRefreshData: PropTypes.func,
  getRowId: PropTypes.func.isRequired,
  height: PropTypes.string,
  checkboxSelection: PropTypes.bool,
  paginationMode: PropTypes.string,
  toolbar: PropTypes.node,
  onRowSelection: PropTypes.func,
};

export default CommonListView;
